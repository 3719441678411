import React, { useState } from "react";
import { Button, Form, FormGroup, FormText, Input } from "reactstrap";
import RestaurantAdminService from "services/RestaurantAdminService";
import { addToWhitelist } from "services/WhitelistService";

const WhitelistManualAdd = ({ closeModal, fetchWhitelist, restaurantId }) => {
  const [walletAddress, setWalletAddress] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleError = (message) => {
    setError(message);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    console.log(walletAddress);
    if (walletAddress.length !== 42) {
      handleError("Invalid Wallet Address");
      setLoading(false);
      return;
    }

    addToWhitelist(restaurantId, [walletAddress])
      .then((res) => {
        console.log(res);
        fetchWhitelist();
        closeModal();
      })
      .catch((e) => {
        console.log(e);
        handleError("Something Went Wrong");
        setLoading(false);
      });
  };

  return (
    <Form onSubmit={(e) => handleSubmit(e)}>
      <FormGroup>
        <FormText className="mb-2">
          Enter Wallet Address to add to Whitelist
        </FormText>
        <Input
          className="mb-2"
          value={walletAddress}
          onChange={(e) => setWalletAddress(e.target.value)}
        />
        <Button type="submit" disabled={loading}>
          Submit
        </Button>
      </FormGroup>

      {error !== "" && (
        <b className="text-danger">
          <i className="tim-icons icon-alert-circle-exc mr-1 font-weight-bolder" />
          {error.charAt(0).toUpperCase() + error.substring(1)}
        </b>
      )}
    </Form>
  );
};

export default WhitelistManualAdd;
