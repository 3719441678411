import React, { useEffect, useState } from "react";
import ReactNotificationAlert from "react-notification-alert";
import { Button, Modal, ModalBody, ModalHeader, Table } from "reactstrap";
import RestaurantAdminService from "services/RestaurantAdminService";
import AddSubAdmin from "./AddSubAdmin";
import { useStoreState } from "easy-peasy";

const Settings = () => {
  const [subAdmins, setSubAdmins] = useState([]);
  const [loading, setLoading] = useState(false);
  const role = useStoreState((states) => states.role);
  const [modalOpen, setModalOpen] = useState(false);
  // const [walletAddress, setWalletAddress] = useState("");
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const toggleDeleteConfirmation = () => {
    setDeleteConfirmation(!deleteConfirmation);
  };

  useEffect(() => {
    if (role === "RESTO_ADMIN") fetchSubAdmins();
  }, []);

  const fetchSubAdmins = async () => {
    setLoading(true);
    try {
      const res = await RestaurantAdminService.getSubAdmins();
      console.log(res.data);
      setSubAdmins(res.data);
      setLoading(false);
    } catch (e) {
      console.log("ERROR", e);
      notify("Couldn't fetch sub admins");
      setLoading(false);
    }
  };

  const handleDelete = (walletAddress) => {
    setLoading(true);
    if (window.confirm("Delete sub admin " + walletAddress + "?")) {
      RestaurantAdminService.deleteSubAdmin(walletAddress)
        .then((res) => {
          console.log(res);
          fetchSubAdmins();
        })
        .catch((e) => {
          console.log(e);
          notify("Couldn't delete sub admin");
          setLoading(false);
        });
    }
  };

  const notificationAlertRef = React.useRef(null);
  const notify = (
    message = <span>Something went wrong</span>,
    type = "danger"
  ) => {
    var options = {
      place: "bc",
      message: message,
      type: type,
      icon: "tim-icons  icon-alert-circle-exc",
      autoDismiss: 5,
    };
    notificationAlertRef.current?.notificationAlert(options);
  };

  return (
    <div className="content settings">
      <div className="react-notification-alert-container">
        <ReactNotificationAlert ref={notificationAlertRef} />
      </div>

      <h3 className="mb-1">Sub Admins</h3>
      <p>
        Sub Admins can login to the dashboard with limited functionality, and
        can perform tasks like reservation scanning, verifying and admitting
        guests.
      </p>

      {role === "RESTO_ADMIN" ? (
        <>
          <h4 className="mt-4">Current Sub Admins</h4>

          <div className="overflow-auto">
            <Table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Wallet Address</th>
                  <th></th>
                </tr>
              </thead>
              {subAdmins.map((subAdmin, i) => (
                <tbody>
                  <tr>
                    {" "}
                    <td>{subAdmin.name}</td>
                    <td>{subAdmin.walletAddress}</td>
                    <td>
                      <Button
                        size="sm"
                        className="p-2"
                        onClick={() => handleDelete(subAdmin.walletAddress)}
                      >
                        <i className="tim-icons icon-simple-remove" />
                      </Button>
                    </td>
                  </tr>
                </tbody>
              ))}
            </Table>
          </div>

          <Button onClick={() => setModalOpen(true)} disabled={loading}>
            Add more
          </Button>
        </>
      ) : (
        <h3 className="text-danger">You don't have access to this section.</h3>
      )}

      {/* <Modal
        isOpen={deleteConfirmation}
        toggle={toggleDeleteConfirmation}
        backdrop={true}
        centered={true}
        fade={true}
      >
        <ModalHeader toggle={toggleDeleteConfirmation} className="d-inline">
          <h3>Delete sub admin {walletAddress}?</h3></ModalHeader> 
      </Modal> */}

      <Modal
        isOpen={modalOpen}
        toggle={toggleModal}
        backdrop={true}
        centered={true}
        fade={true}
        className="sub-admin-modal"
      >
        <ModalHeader toggle={toggleModal} className="d-inline">
          <h3 className="mb-0">Add new Sub Admin</h3>
        </ModalHeader>
        <ModalBody>
          <AddSubAdmin
            toggleModal={toggleModal}
            fetchSubAdmins={fetchSubAdmins}
          />
        </ModalBody>

        {/* <button aria-label="Close" className="close" onClick={toggleModal}>
            <i className="tim-icons icon-simple-remove" />
          </button> */}
      </Modal>
    </div>
  );
};

export default Settings;
