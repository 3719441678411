/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import LoaderFood from "components/Loaders/LoaderFood";
import { useStoreState } from "easy-peasy";
import { ethers } from "ethers";
import React, { useEffect, useState } from "react";
import ReactNotificationAlert from "react-notification-alert";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import RestaurantService from "services/RestaurantService";

function UserProfile() {
  // const [restaurantId] = useState(sessionStorage.getItem("restaurantId"));

  const restaurantId = useStoreState((states) => states.restaurantId);
  const role = useStoreState((states) => states.role);
  const [name, setName] = useState("");
  const [cuisineType, setCuisineType] = useState("");
  const [priceIndicator, setPriceIndicator] = useState(2);
  const [addLine1, setAddLine1] = useState("");
  const [addLine2, setAddLine2] = useState("");
  const [location, setLocation] = useState("");
  const [cityId, setCityId] = useState();
  const [country, setCountry] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [email, setEmail] = useState("");
  const [restaurantEmail, setRestaurantEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [website, setWebsite] = useState("");
  const [instagram, setInstagram] = useState("");
  const [facebook, setFacebook] = useState("");
  const [twitter, setTwitter] = useState("");
  const [whyWeLikeIt, setWhyWeLikeIt] = useState("");
  const [needToKnow, setNeedToKnow] = useState("");
  const [about, setAbout] = useState("");
  const [registeredOnChain, setRegisteredOnChain] = useState(false);
  const [creator, setCreator] = useState("");
  const [walletAddress, setWalletAddress] = useState("");
  const [royalty, setRoyalty] = useState(0);

  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);

  const [cities, setCities] = useState([]);

  const notificationAlertRef = React.useRef(null);

  useEffect(() => {
    try {
      setLoading(true);

      RestaurantService.getRestaurantById(restaurantId).then((res) => {
        console.log(res.data);
        setName(res.data?.name);
        setCuisineType(res.data?.cuisineType);
        setPriceIndicator(res.data?.priceIndicator);
        setAddLine1(res.data?.line1);
        setAddLine2(res.data?.line2);
        setLocation(res.data?.location);
        // setCity(res.data?.city);
        setCityId(res.data?.cityId);
        setCountry(res.data?.country);
        setPostalCode(res.data?.zipcode);
        setEmail(res.data?.email);
        setRestaurantEmail(res.data?.restaurantEmail);
        setMobile(res.data?.mobile);
        setWebsite(res.data?.website);
        setInstagram(res.data?.instagram);
        setFacebook(res.data?.facebook);
        setTwitter(res.data?.twitter);
        setWhyWeLikeIt(res.data?.whywelikeit);
        setNeedToKnow(res.data?.needtoknow);
        setAbout(res.data?.about);
        setRegisteredOnChain(res.data?.isRegisteredOnChain);
        setCreator(res.data?.creatorAddress);
        setWalletAddress(res.data?.walletAddress);
        setRoyalty(res.data?.royaltyPercent);
      });

      setLoading(false);
    } catch (e) {
      notify("Something went wrong");
    }
  }, []);

  useEffect(() => {
    fetchCities();
  }, []);

  useEffect(() => {
    console.log("Priceindicator:", priceIndicator);
  }, [priceIndicator]);

  const notify = (
    message = <span>Something went wrong</span>,
    type = "danger"
  ) => {
    var options = {
      place: "bc",
      message: message,
      type: type,
      icon: "tim-icons  icon-alert-circle-exc",
      autoDismiss: 5,
    };
    notificationAlertRef.current?.notificationAlert(options);
  };

  const fetchCities = async () => {
    try {
      const res = await RestaurantService.getCities();
      setCities(res.data);
    } catch (e) {
      console.log("ERROR::", e);
    }
  };

  const handleSubmit = () => {
    console.log("Submit button clicked");
    setUpdating(true);
    let restaurantData = {
      priceIndicator,
      cuisineType,
      mobile,
      description: about,
      cityId,
      line1: addLine1,
      website,
      email,
      restaurantEmail,
      // instagram,
      // facebook,
      // twitter,
      // whyWeLikeIt,
      needToKnow,
      about,
    };
    if (!registeredOnChain) {
      restaurantData.name = name;
      restaurantData.location = location;
    }
    RestaurantService.updateRestaurantAbout(restaurantId, restaurantData)
      .then((res) => {
        notify("Details updated", "success");
        setUpdating(false);
      })
      .catch((e) => {
        console.log(e);
        notify();
        setUpdating(false);
      });
  };

  return (
    <>
      <div className="content about">
        <div className="react-notification-alert-container">
          <ReactNotificationAlert ref={notificationAlertRef} />
        </div>
        {!loading && (
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <h5 className="title">Edit Restaurant Details</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-md-1" md="6">
                        <FormGroup>
                          <label>Name</label>
                          <Input
                            placeholder="Restaurant Name"
                            type="text"
                            value={name}
                            disabled={registeredOnChain}
                            title={
                              registeredOnChain
                                ? "Already registered on Chain, can't update name"
                                : ""
                            }
                            onChange={(e) => setName(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pl-md-1" md="6">
                        <FormGroup>
                          <label>Admin Email</label>
                          <Input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pr-md-1 pr-sm-1" xs="6" md="3">
                        <FormGroup>
                          <label>Cuisine Type</label>
                          <Input
                            placeholder="Cuisine Type"
                            type="text"
                            value={cuisineType}
                            onChange={(e) => setCuisineType(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pl-md-1 pl-sm-1" xs="6" md="3">
                        <FormGroup>
                          <label htmlFor="exampleInputEmail1">
                            Price Indicator
                          </label>
                          <Input
                            placeholder="price"
                            type="select"
                            value={priceIndicator}
                            onChange={(e) =>
                              setPriceIndicator(Number(e.target.value))
                            }
                          >
                            <option value="" hidden></option>
                            <option value={"1"}>$</option>
                            <option value={"2"}>$$</option>
                            <option value={"3"}>$$$</option>
                            <option value={"4"}>$$$$</option>
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col className="pr-md-1" md="6" lg="4">
                        <FormGroup>
                          <label>Address line 1</label>
                          <Input
                            // defaultValue="Bld Mihail Kogalniceanu, nr. 8 Bl 1, Sc 1, Ap 09"
                            placeholder="Home Address"
                            type="text"
                            value={addLine1}
                            onChange={(e) => {
                              setAddLine1(e.target.value);
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="px-md-1" md="6" lg="4">
                        <FormGroup>
                          <label>Address line 2</label>
                          <Input
                            // defaultValue="Bld Mihail Kogalniceanu, nr. 8 Bl 1, Sc 1, Ap 09"
                            placeholder="Home Address"
                            type="text"
                            value={addLine2}
                            onChange={(e) => {
                              setAddLine2(e.target.value);
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pl-lg-1" md="6" lg="4">
                        <FormGroup>
                          <label>Location</label>
                          <Input
                            type="text"
                            value={location}
                            disabled={registeredOnChain}
                            title={
                              registeredOnChain
                                ? "Already registered on Chain, can't update location"
                                : ""
                            }
                            onChange={(e) => {
                              setLocation(e.target.value);
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pr-md-1" md="4">
                        <FormGroup>
                          <label>City</label>
                          <Input
                            type="select"
                            value={cityId}
                            onChange={(e) => {
                              setCityId(Number(e.target.value));
                            }}
                          >
                            {cities.map((city, i) => (
                              <option value={city.cityId} key={i}>
                                {city.city}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      {/* <Col className="px-md-1" md="4">
                      <FormGroup>
                        <label>Country</label>
                        <Input
                          type="text"
                          value={country}
                          onChange={(e)=>setCountry(e.target.value)}
                        />
                      </FormGroup>
                    </Col> */}
                      <Col className="pl-md-1" md="4">
                        <FormGroup>
                          <label>Postal Code</label>
                          <Input
                            type="number"
                            value={postalCode}
                            onChange={(e) => setPostalCode(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <h3 className=" mt-2 subheading">Contact</h3>
                    <Row>
                      <Col className="pr-md-1" md="4">
                        <FormGroup>
                          <label>Restaurant Email</label>
                          <Input
                            type="email"
                            value={restaurantEmail}
                            onChange={(e) => setRestaurantEmail(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="px-md-1" md="4">
                        <FormGroup>
                          <label>Mobile</label>
                          <Input
                            type="text"
                            value={mobile}
                            onChange={(e) => setMobile(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pl-md-1" md="4">
                        <FormGroup>
                          <label>Website</label>
                          <Input
                            type="text"
                            value={website}
                            onChange={(e) => setWebsite(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <h3 className=" mt-2 subheading">Social</h3>
                    <Row>
                      <Col className="pr-md-1" md="4">
                        <FormGroup>
                          <label>Instagram</label>
                          <Input
                            type="text"
                            value={instagram}
                            onChange={(e) => setInstagram(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="px-md-1" md="4">
                        <FormGroup>
                          <label>Facebook</label>
                          <Input
                            value={facebook}
                            onChange={(e) => setFacebook(e.target.value)}
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pl-md-1" md="4">
                        <FormGroup>
                          <label>Twitter</label>
                          <Input
                            value={twitter}
                            onChange={(e) => setTwitter(e.target.value)}
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <h3 className=" mt-2 subheading">Details</h3>
                    <Row>
                      <Col className="pr-md-1" md="6">
                        <FormGroup>
                          <label>Why we like it</label>
                          <Input
                            type="textarea"
                            rows={8}
                            value={whyWeLikeIt}
                            onChange={(e) => setWhyWeLikeIt(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pr-md-1" md="6">
                        <FormGroup>
                          <label>Need to know</label>
                          <Input
                            type="textarea"
                            rows={8}
                            value={needToKnow}
                            onChange={(e) => setNeedToKnow(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col className="pr-md-1" md="12">
                        <FormGroup>
                          <label>About</label>
                          <Input
                            type="textarea"
                            rows={8}
                            value={about}
                            onChange={(e) => setAbout(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <h3 className=" mt-2 subheading">NFT</h3>
                    <Row>
                      <Col className="pr-md-1" md="6">
                        <FormGroup>
                          <label>Creator address</label>
                          <Input
                            type="text"
                            value={creator}
                            disabled
                            // onChange={(e) => setWhyWeLikeIt(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pr-md-1" md="6">
                        <FormGroup>
                          <label>Wallet address</label>
                          <Input
                            type="text"
                            value={walletAddress}
                            disabled
                            // onChange={(e) => setWhyWeLikeIt(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={4}>
                        <FormGroup>
                          <label>Royalty Percentage</label>
                          <Input
                            type="text"
                            value={royalty}
                            disabled
                            // onChange={(e) => setWhyWeLikeIt(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
                <CardFooter>
                  <Button
                    className="paidrez-btn"
                    type="submit"
                    onClick={handleSubmit}
                    disabled={role !== "RESTO_ADMIN"}
                  >
                    Save
                  </Button>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        )}
      </div>

      {updating && (
        <div className="nft-loader-container fullscreen topmost">
          <LoaderFood className="nft-loader" />
        </div>
      )}
    </>
  );
}

export default UserProfile;
