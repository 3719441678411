import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Table,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
  Input,
  Label,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import WhitelistCSV from "./popups/WhitelistCSV";
import { getWhitelist } from "services/WhitelistService";
import { useStoreState } from "easy-peasy";
import WhitelistManualAdd from "./popups/WhitelistManualAdd";
import { removeFromWhitelist } from "services/WhitelistService";
import { toggleWhitelistStatus } from "services/WhitelistService";
import RestaurantService from "services/RestaurantService";
import ReactNotificationAlert from "react-notification-alert";
import { resetWhitelist } from "services/WhitelistService";
import WhitelistDeleteConfirmation from "./popups/WhitelistDeleteConfirmation";
import WhitelistToggleConfirmation from "./popups/WhitelistToggleConfirmation";
import WhitelistResetConfirmation from "./popups/WhitelistResetConfirmation";

const Whitelist = () => {
  const [whitelist, setWhitelist] = useState([]);
  const [whitelistStatus, setWhitelistStatus] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalNumber, setModalNumber] = useState(-1);
  const [statusLoader, setStatusLoader] = useState(true);
  const [loader, setLoader] = useState(true);
  const [selectedWalletAddress, setSelectedWalletAddress] = useState(null);

  const restaurantId = useStoreState((states) => states.restaurantId);
  const headers = useStoreState((states) => states.headers);

  const notificationAlertRef = useRef(null);

  useEffect(() => {
    if (headers && restaurantId) {
      fetchWhitelistStatus();
      fetchWhitelist();
    }
  }, [headers, restaurantId]);

  useEffect(() => {
    if (modalNumber === -1) {
      setModal(false);
    } else {
      setModal(true);
    }
  }, [modalNumber]);

  useEffect(() => {
    if (selectedWalletAddress) {
      setModalNumber(2);
    }
  }, [selectedWalletAddress]);

  const notify = (message = <span>Something went wrong</span>) => {
    var options = {
      place: "bc",
      message: message,
      type: "danger",
      icon: "tim-icons  icon-alert-circle-exc",
      autoDismiss: 5,
    };

    notificationAlertRef.current?.notificationAlert(options);
  };

  const handleDelete = async (walletAddress) => {
    setLoader(true);
    try {
      const res = await removeFromWhitelist(restaurantId, [walletAddress]);
      console.log(res);
      fetchWhitelist();
      closeModal();
    } catch (e) {
      closeModal();
      console.log("ERROR::", e);
      notify();
      setLoader(false);
    }
  };

  const handleReset = async () => {
    setStatusLoader(true);
    try {
      const res = await resetWhitelist(restaurantId);
      console.log(res);
      await fetchWhitelistStatus();
      setStatusLoader(false);
      closeModal();
    } catch (e) {
      console.log("ERROR::", e);
      closeModal();
      setStatusLoader(false);
      notify();
    }
  };

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  const closeModal = () => {
    setSelectedWalletAddress(null);
    setModalNumber(-1);
  };

  const fetchWhitelist = async () => {
    setLoader(true);
    try {
      const res = await getWhitelist(restaurantId);
      console.log(res);
      setWhitelist(res.data?.data?.whitelist);
      setLoader(false);
    } catch (e) {
      console.log("ERROR::", e);
      notify();
      setLoader(false);
    }
  };

  const fetchWhitelistStatus = async () => {
    try {
      const res = await RestaurantService.getRestaurantById(restaurantId);
      setWhitelistStatus(res.data?.isWhitelistEnabled);
      setStatusLoader(false);
    } catch (e) {
      console.log("ERROR::", e);
      notify();
    }
  };

  const handleToggle = async () => {
    setStatusLoader(true);
    try {
      const res = await toggleWhitelistStatus(restaurantId);
      console.log(res);
      setWhitelistStatus(res.data?.result?.isWhitelistEnabled);
      setStatusLoader(false);
      closeModal();
    } catch (e) {
      console.log("ERROR::", e);
      setStatusLoader(false);
      notify();
      closeModal();
    }
  };

  return (
    <div className="content whitelist">
      <h3>Maintain Whitelist - Implement Exclusivility</h3>
      <h4>Reservations can be minted by only whitelisted accounts.</h4>
      <h4>Note: All the active slots will be treated as whitelist only.</h4>
      <h4>
        White list status - {whitelistStatus ? "Active" : "Disabled"}
        <span>
          <Button
            className="mx-3"
            onClick={() => {
              setModalNumber(3);
            }}
            disabled={statusLoader}
          >
            Turn {whitelistStatus ? "Off" : "On"}
          </Button>
        </span>
      </h4>

      <div className="list overflow-auto">
        <Table>
          <thead>
            <tr>
              <th>Wallet Address</th>
              <th>Name</th>
              <th>Email</th>
              <th>Mobile</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {whitelist?.map((guest, i) => (
              <tr key={i}>
                <td>{guest.walletAddress}</td>
                <td>{guest.name}</td>
                <td>{guest.email}</td>
                <td>{guest.mobile}</td>
                <td>
                  <Button
                    onClick={() => {
                      setSelectedWalletAddress(guest.walletAddress);
                    }}
                    disabled={loader}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <div className="buttons-container">
        <Button
          className="btn-danger"
          disabled={loader}
          onClick={() => setModalNumber(4)}
        >
          Reset List
        </Button>
        {/* <Button>Add More</Button> */}
        <Dropdown
          isOpen={dropdownOpen}
          toggle={toggleDropdown}
          className="add-more-button"
        >
          <DropdownToggle caret disabled={loader}>
            Add More
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => setModalNumber(0)}>
              Add manually
            </DropdownItem>
            <DropdownItem onClick={() => setModalNumber(1)}>
              Upload CSV file
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>

      <Modal
        isOpen={modal}
        toggle={closeModal}
        backdrop={true}
        centered={true}
        fade={true}
        fullscreen={true}
        className="qrcode-modal"
      >
        <ModalHeader toggle={closeModal} className="mx-auto">
          Whitelist
        </ModalHeader>
        <ModalBody>
          {
            {
              0: (
                <WhitelistManualAdd
                  closeModal={closeModal}
                  fetchWhitelist={fetchWhitelist}
                  restaurantId={restaurantId}
                />
              ),

              1: (
                <WhitelistCSV
                  closeModal={closeModal}
                  fetchWhitelist={fetchWhitelist}
                  restaurantId={restaurantId}
                />
              ),

              2: (
                <WhitelistDeleteConfirmation
                  closeModal={closeModal}
                  walletAddress={selectedWalletAddress}
                  handleDelete={handleDelete}
                  loader={loader}
                />
              ),

              3: (
                <WhitelistToggleConfirmation
                  closeModal={closeModal}
                  handleToggle={handleToggle}
                  statusLoader={statusLoader}
                  whitelistStatus={whitelistStatus}
                />
              ),

              4: (
                <WhitelistResetConfirmation
                  closeModal={closeModal}
                  handleReset={handleReset}
                  loader={loader}
                />
              ),
            }[modalNumber]
          }
        </ModalBody>
        {/* <ModalFooter>
          <Button color="primary" onClick={toggle}>
            Do Something
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter> */}
      </Modal>

      <div className="react-notification-alert-container">
        <ReactNotificationAlert ref={notificationAlertRef} />
      </div>
    </div>
  );
};

export default Whitelist;
