import React, { useLayoutEffect } from "react";
import ReactNotificationAlert from "react-notification-alert";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import PaidrezLogo from "../assets/img/paidrez-logo.png";
import { useStoreState } from "easy-peasy";
import Web3AuthComponent from "components/Web3Auth/Web3AuthComponent";
import QrCodeScanner from "./QrCodeScanner";
import Tables from "./UpcomingReservations";

const Login = () => {
  const history = useHistory();
  const notificationAlertRef = React.useRef(null);

  const isLoggedIn = useStoreState((state) => state.isLoggedIn);

  useLayoutEffect(() => {
    console.log("use layout effect");
    if (isLoggedIn) {
      history.push("/admin/upcoming-reservations");
    }
  }, [isLoggedIn]);

  return (
    <div className="login">
      <img
        src={PaidrezLogo}
        alt="paidrez-logo"
        width={150}
        className="d-block mx-auto mt-3 mb-5"
      />

      <div className="react-notification-alert-container">
        <ReactNotificationAlert ref={notificationAlertRef} />
      </div>

      <h3 className="text-center ">Manage your restaurant on Paidrez</h3>

      <div className="web3auth-login-container">
        <Web3AuthComponent />

        <div className="buttons-container d-flex justify-content-between">
          <Button
            className="btn-link"
            type="button"
            href="mailto:paidrezrestaurant@gmail.com"
          >
            Need help?
          </Button>
          <Button
            className="btn-link"
            type="button"
            href="mailto:paidrezrestaurant@gmail.com"
          >
            List your restaurant
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Login;
