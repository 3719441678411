import React, { useEffect, useRef, useState } from "react";

import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  Input,
  FormGroup,
  Label,
  Form,
  CardImg,
  CardText,
  InputGroup,
  InputGroupText,
} from "reactstrap";

import DatePicker from "react-horizontal-datepicker";
import TimeInput from "react-time-picker-input";
import "react-time-picker-input/dist/components/TimeInput.css";
import SlotsService from "services/SlotsService";
import RestaurantService from "services/RestaurantService";
import LoaderFood from "components/Loaders/LoaderFood";
import ReactNotificationAlert from "react-notification-alert";
import moment from "moment/moment";
import LoaderSpoon from "components/Loaders/LoaderSpoon";
import { useStoreState } from "easy-peasy";
import { ethers } from "ethers";
import giftIcon from "../assets/img/gift-icon.png";

const TimeSlots = () => {
  const [slots, setSlots] = useState([]);
  const [slotsLoading, setSlotsLoading] = useState(true);
  const [showNewSlot, setShowNewSlot] = useState(false);
  const [newTime, setNewTime] = useState("10:15");
  const [date, setDate] = useState(new Date());
  const [newSlotPaxCount, setNewSlotPaxCount] = useState(2);
  const [newNftImage, setNewNFTImage] = useState(null);
  const [mintFee, setMintFee] = useState("0.0");
  const [preview, setPreview] = useState();
  const [restaurant, setRestaurant] = useState({});
  const [web3Loading, setWeb3Loading] = useState(false);
  const [restaurantDetailsLoading, setRestaurantDetailsLoading] =
    useState(true);
  const [isRegisteredOnChain, setIsRegisteredOnChain] = useState(false);
  const [addingSlot, setAddingSlot] = useState(false);

  const restaurantId = useStoreState((states) => states.restaurantId);
  const headers = useStoreState((states) => states.headers);
  const role = useStoreState((state) => state.role);

  const imageInputRef = useRef();
  const notificationAlertRef = useRef(null);

  const notify = (message = <span>Something went wrong</span>) => {
    var options = {
      place: "bc",
      message: message,
      type: "danger",
      icon: "tim-icons  icon-alert-circle-exc",
      autoDismiss: 5,
    };

    notificationAlertRef.current?.notificationAlert(options);
  };

  useEffect(() => {
    if (role === "RESTO_ADMIN") {
      setRestaurantDetailsLoading(true);
      console.log("fetch restaurant details");
      RestaurantService.getRestaurantById(restaurantId)
        .then((res) => {
          console.log("restaurant:", res.data);
          setRestaurant(res.data);
          setIsRegisteredOnChain(res.data.isRegisteredOnChain);
          setRestaurantDetailsLoading(false);
        })
        .catch((e) => {
          notify();
          console.log(e);
        });
    }
  }, []);

  const fetchSlots = () => {
    setSlotsLoading(true);
    console.log("fetchslotsfunction");
    const startTime = moment(date)
      // .utc()
      // .set({
      //   year: date.getFullYear(),
      //   month: date.getMonth(),
      //   date: date.getDate(),
      // })
      .startOf("day")
      .unix();
    const endTime = moment(date)
      // .utc()
      // .set({
      //   year: date.getFullYear(),
      //   month: date.getMonth(),
      //   date: date.getDate(),
      // })
      .endOf("day")
      .unix();

    SlotsService.getSlots({ restoId: restaurant.restoId, startTime, endTime })
      .then((res) => {
        console.log(res.data);
        setSlots(res.data.slots);
        setSlotsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        notify("Couldn't fetch slots");
        setSlotsLoading(false);
      });
  };

  useEffect(() => {
    if (role === "RESTO_ADMIN") {
      if (date && !showNewSlot && !restaurantDetailsLoading && headers)
        fetchSlots();
    }
  }, [date, headers]);

  const selectedDay = (val) => {
    setDate(val);
  };

  const addNewSlot = () => {
    if (!newNftImage) {
      notify("Please select NFT image");
      return;
    }

    setAddingSlot(true);

    const slotTime = moment(date)
      .set({
        hour: newTime.substring(0, 2),
        minute: newTime.substring(3, 5),
        second: "00",
      })
      .toString();

    // --- Solution For multiple time zones, has bugs ---
    // const splitDate = date.toISOString().split("T");
    // const slotTime = splitDate[0] + "T" + newTime + splitDate[1].substring(5);

    console.log(slotTime);

    let epoch = Math.floor(new Date(slotTime) / 1000);
    // console.log(epoch);
    // return;
    const price = ethers.utils.parseUnits(mintFee, "ether").toString();

    const slot = {
      restoId: restaurant.restoId,
      nftImage: newNftImage,
      slotTime: epoch,
      seatCapacity: newSlotPaxCount,
      duration: 1,
      price,
    };
    SlotsService.addSlot(slot)
      .then((res) => {
        console.log(res);
        setNewNFTImage(null);
        setNewSlotPaxCount(2);
        setShowNewSlot(false);
        setAddingSlot(false);
        fetchSlots();
      })
      .catch((e) => {
        setAddingSlot(false);
        notify();
        console.log(e);
      });
  };

  const deleteSlot = (slotId) => {
    if (window.confirm("Are you sure to delete " + slotId)) {
      // console.log("ok clicked");
      // saveChanges();
      SlotsService.deleteSlot(slotId)
        .then((res) => {
          console.log(res);
          fetchSlots();
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const nftImageInputFunction = () => {
    imageInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    setNewNFTImage(event.target.files[0]);
    console.log(event.target.files[0]);
  };

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!newNftImage) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(newNftImage);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [newNftImage]);

  const handleConfirm = async (slot) => {
    setWeb3Loading(true);

    SlotsService.confirmSlot(slot._id)
      .then((res) => {
        setWeb3Loading(false);
        fetchSlots();
      })
      .catch((e) => {
        console.log(e);
        notify();
        setWeb3Loading(false);
      });
  };

  const handleDisable = async (res) => {
    const action = res.isSlotActive ? "disable" : "enable";
    if (
      window.confirm(
        "Are you sure to " +
          action +
          " the reservation for " +
          moment(res.slotTime * 1000).format("LT on M/D/YYYY") +
          "?"
      )
    ) {
      try {
        setSlotsLoading(true);
        const result = await SlotsService.toggleSlotStatus(
          res.restaurantId,
          res._id
        );
        console.log("Slot status toggled:", result);
        fetchSlots();
      } catch (e) {
        console.log("ERROR::", e);
        setSlotsLoading(false);
        // setErrorAlert(true);
        // setTimeout(() => {
        //   setErrorAlert(false);
        // }, 2000);
      }
    }
  };

  return (
    <>
      <div className="content time-slots">
        <h3> Time Slots</h3>
        {role === "RESTO_ADMIN" ? (
          <>
            {!restaurantDetailsLoading && (
              <>
                {!isRegisteredOnChain ? (
                  <h1 className="text-center text-danger">
                    Restaurant not registered!
                    <br />
                    <br />
                    <small className="text-dark">
                      {" "}
                      Please contact paidrez admin to register your restaurant
                      on blockchain
                    </small>
                  </h1>
                ) : (
                  <>
                    <div className="datepicker-container">
                      <DatePicker
                        getSelectedDay={selectedDay}
                        selectDate={new Date(date)}
                        labelFormat={"MMMM"}
                        // color="rgb(10,105,126)"
                        color="#0c8ba7"
                        className="mb-2"
                      />
                    </div>

                    <div className="react-notification-alert-container">
                      <ReactNotificationAlert ref={notificationAlertRef} />
                    </div>

                    {showNewSlot ? (
                      <>
                        <div className="text-center add-new-slot-container my-4">
                          <h1 className="header mb-0">Add new time slot</h1>
                          <hr />

                          {/* <div className="datepicker-container">
                    <DatePicker
                      getSelectedDay={selectedDay}
                      labelFormat={"MMMM"}
                      selectDate={new Date(date)}
                      // color="rgb(10,105,126)"
                      color="#0c8ba7"
                      className="add-new-slot-date-picker"
                    />
                  </div> */}

                          <div className="new-slots-container">
                            <Form className="text-center number-of-slots-container">
                              <FormGroup className="col px-0 mb-0">
                                <Label>Time</Label>
                                <TimeInput
                                  value={newTime}
                                  hour24Format
                                  eachInputDropdown
                                  manuallyDisplayDropdown
                                  onChange={(dateString) => {
                                    setNewTime(dateString);
                                    console.log(dateString);
                                  }}
                                  className="mx-2"
                                />
                              </FormGroup>
                              <FormGroup className="col px-0 mb-0">
                                <Label>Number of guests</Label>
                                <Input
                                  type="select"
                                  onChange={(e) => {
                                    setNewSlotPaxCount(Number(e.target.value));
                                  }}
                                >
                                  {/* <option>Number of guests</option> */}
                                  <option value="2">2</option>
                                  <option value="4">4</option>
                                  <option value="6">6</option>
                                </Input>
                              </FormGroup>
                            </Form>

                            <div className="nft-image-options">
                              {newNftImage ? (
                                <div className="image-preview-container">
                                  <img src={preview} alt="" width={250} />
                                  <p className="image-name">
                                    {
                                      <small>
                                        File chosen: {newNftImage.name}
                                      </small>
                                    }
                                  </p>
                                  <Button
                                    size="sm"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      nftImageInputFunction();
                                    }}
                                    disabled={addingSlot}
                                  >
                                    Change
                                  </Button>
                                </div>
                              ) : (
                                <div
                                  className="add-image-container"
                                  onClick={nftImageInputFunction}
                                >
                                  <svg
                                    width="46"
                                    height="45"
                                    viewBox="0 0 46 45"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="mb-3"
                                  >
                                    <path
                                      d="M3.03076 32.1392L14.4775 20.6924C16.4271 18.7428 19.588 18.7428 21.5376 20.6924L32.9844 32.1392M27.9921 27.1469L31.9504 23.1886C33.9 21.239 37.061 21.2389 39.0106 23.1886L42.9689 27.1469M27.9921 12.1701H28.0171M8.02303 42.1237H37.9766C40.7338 42.1237 42.9689 39.8886 42.9689 37.1314V7.17781C42.9689 4.42066 40.7338 2.18555 37.9766 2.18555H8.02303C5.26588 2.18555 3.03076 4.42066 3.03076 7.17781V37.1314C3.03076 39.8886 5.26588 42.1237 8.02303 42.1237Z"
                                      stroke="#0E91AE"
                                      strokeWidth="4.10134"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>

                                  <p>
                                    <span>Upload a file</span> or drag and drop
                                  </p>
                                  <p className="text-black-50">
                                    {" "}
                                    <small> PNG, JPG, GIF upto 5MB</small>
                                  </p>
                                </div>
                              )}
                              <input
                                type="file"
                                name="nft-image-input"
                                accept=".png, .jpg, .jpeg, .avif, .webp"
                                onChange={handleFileChange}
                                ref={imageInputRef}
                                className={"d-none"}
                              />
                            </div>

                            <FormGroup className="mint-fees-container mt-3">
                              <Label className="float-left mb-1">
                                Mint Fee
                              </Label>
                              <InputGroup>
                                <Input
                                  type="number"
                                  className="text-left"
                                  value={mintFee}
                                  step={0.1}
                                  min={0}
                                  onChange={(e) => {
                                    setMintFee(e.target.value);
                                  }}
                                />
                                <InputGroupText>MATIC</InputGroupText>
                              </InputGroup>
                            </FormGroup>

                            {/* <br /> */}
                            {/* </> */}
                            {/* ))} */}
                          </div>

                          <Col sm={12} className="mx-2">
                            <Button
                              className=""
                              onClick={addNewSlot}
                              disabled={addingSlot}
                            >
                              Save
                            </Button>
                            <Button
                              className="btn-danger"
                              onClick={() => {
                                setNewNFTImage(null);
                                setShowNewSlot(false);
                              }}
                              disabled={addingSlot}
                            >
                              Cancel
                            </Button>
                          </Col>
                        </div>
                      </>
                    ) : (
                      <>
                        <Row
                          className="slots-container mx-0 my-5"
                          xs={1}
                          sm={2}
                          md={3}
                          xl={4}
                          xxl={6}
                        >
                          {slotsLoading ? (
                            <div className="slots-loader-container">
                              <LoaderSpoon className="slots-loader" />
                            </div>
                          ) : (
                            <>
                              <Col className="nft px-2">
                                <div
                                  className="p-2 card add-new-nft-card-container"
                                  onClick={() => setShowNewSlot(true)}
                                >
                                  <div className="m-auto">
                                    <div className="plus-icon">+</div>
                                    <div>Add New Time Slot</div>
                                  </div>
                                </div>
                              </Col>

                              {slots.map((slot, i) => (
                                <Col key={i} className="nft px-2">
                                  <Card className="p-2 mb-0">
                                    <CardImg
                                      variant="top"
                                      src={slot.s3ImageURI}
                                      alt="nft-image"
                                      className="nft-image"
                                    />
                                    <CardBody className="py-0">
                                      <CardText as="div">
                                        <Row className="mb-1 mt-3">
                                          <Col
                                            xs={6}
                                            className="nft-info-container seats"
                                          >
                                            <div className="nft-info ">
                                              <svg
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="icon"
                                              >
                                                <path
                                                  d="M14.9856 11.5371C16.8427 11.9182 17.5219 13.1535 17.5219 14.1851"
                                                  stroke="#9E9E9E"
                                                  strokeWidth="1.14435"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                />
                                                <path
                                                  d="M14.5728 5.2312C15.4182 5.50406 16.028 6.29839 16.0246 7.23476C16.021 8.13127 15.4554 8.89532 14.6619 9.19239"
                                                  stroke="#9E9E9E"
                                                  strokeWidth="1.14435"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                />
                                                <path
                                                  d="M5.00391 15.7532C5.00391 14.1447 6.27378 12.142 9.93094 12.142C13.5881 12.142 14.858 14.1299 14.858 15.7394"
                                                  stroke="#9E9E9E"
                                                  strokeWidth="1.14435"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                />
                                                <path
                                                  fillRule="evenodd"
                                                  clipRule="evenodd"
                                                  d="M13.0792 6.6944C13.0792 8.43289 11.6699 9.84136 9.93224 9.84136C8.19376 9.84136 6.78442 8.43289 6.78442 6.6944C6.78442 4.95589 8.19376 3.54742 9.93224 3.54742C11.6699 3.54742 13.0792 4.95589 13.0792 6.6944Z"
                                                  stroke="#9E9E9E"
                                                  strokeWidth="1.14435"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                />
                                                <path
                                                  d="M4.87711 11.5371C3.01993 11.9182 2.34082 13.1535 2.34082 14.1851"
                                                  stroke="#9E9E9E"
                                                  strokeWidth="1.14435"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                />
                                                <path
                                                  d="M5.28993 5.2312C4.4445 5.50406 3.83469 6.29839 3.83815 7.23476C3.84161 8.13127 4.40726 8.89532 5.20071 9.19239"
                                                  stroke="#9E9E9E"
                                                  strokeWidth="1.14435"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                />
                                              </svg>
                                              <i className="divider"></i>
                                              <span>
                                                {slot.seatCapacity} Seats
                                              </span>
                                            </div>
                                          </Col>{" "}
                                          <Col
                                            xs={6}
                                            className="nft-info-container date"
                                          >
                                            <div className="nft-info ">
                                              <svg
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="icon"
                                              >
                                                <path
                                                  d="M12.0186 2.78455V4.95788"
                                                  stroke="#9E9E9E"
                                                  strokeWidth="1.14435"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                />
                                                <path
                                                  d="M6.68335 2.78455V4.95788"
                                                  stroke="#9E9E9E"
                                                  strokeWidth="1.14435"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                />
                                                <path
                                                  d="M15.2903 8.79628V6.89384C15.2963 4.90756 14.0908 3.82758 12.1461 3.82758H6.55468C4.61516 3.82758 3.40454 4.90756 3.40454 6.89384V12.869C3.40454 14.8864 4.61516 15.9917 6.55468 15.9917H7.96496"
                                                  stroke="#9E9E9E"
                                                  strokeWidth="1.14435"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                />
                                                <path
                                                  fillRule="evenodd"
                                                  clipRule="evenodd"
                                                  d="M16.0491 13.5382C16.0491 15.1831 14.7153 16.5169 13.0705 16.5169C11.4257 16.5169 10.0918 15.1831 10.0918 13.5382C10.0918 11.8934 11.4257 10.5596 13.0705 10.5596C14.7153 10.5596 16.0491 11.8934 16.0491 13.5382Z"
                                                  stroke="#9E9E9E"
                                                  strokeWidth="1.14435"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                />
                                                <path
                                                  d="M13.9959 14.3116L13.054 13.749V12.5384"
                                                  stroke="#9E9E9E"
                                                  strokeWidth="1.14435"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                />
                                                <path
                                                  d="M12.4647 7.84631H6.2312"
                                                  stroke="#9E9E9E"
                                                  strokeWidth="1.14435"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                />
                                              </svg>
                                              <i className="divider"></i>

                                              <span>
                                                {slot.slotTime &&
                                                  moment(
                                                    new Date(
                                                      slot.slotTime * 1000
                                                    )
                                                  ).format("HH:mm")}
                                              </span>
                                            </div>
                                          </Col>
                                        </Row>

                                        <Row>
                                          <Col className="nft-info-container">
                                            <div className="nft-info">
                                              <span className="description ml-1">
                                                Mint Fee{" "}
                                              </span>
                                              <i className="divider"></i>
                                              <span className="value">
                                                {slot.priceInMatic} Matic
                                              </span>
                                            </div>
                                          </Col>
                                        </Row>
                                        {!slot.isConfirmed ? (
                                          <Row
                                            xs={2}
                                            className="buttons-container mb-1"
                                          >
                                            <Col>
                                              <Button
                                                size="sm"
                                                onClick={() =>
                                                  handleConfirm(slot)
                                                }
                                              >
                                                Confirm
                                              </Button>
                                            </Col>
                                            <Col>
                                              <Button
                                                size="sm"
                                                className="delete-button"
                                                color="danger"
                                                onClick={() =>
                                                  deleteSlot(slot._id)
                                                }
                                              >
                                                Delete
                                              </Button>
                                            </Col>
                                          </Row>
                                        ) : (
                                          <Row className="buttons-container mb-1">
                                            <Button
                                              disabled
                                              className="w-100"
                                              size="sm"
                                            >
                                              Confirmed
                                            </Button>
                                            {/* <Form.Check
                                              type="switch"
                                              // checked={reservation.isSlotActive}
                                              onChange={() => {
                                                // handleDisable(reservation);
                                              }}
                                            /> */}

                                            {slot.isSlotActive ? (
                                              <div className="status-container">
                                                <p className="m-0">
                                                  {slot.isMinted
                                                    ? "Slot is Minted"
                                                    : "Slot is active"}
                                                </p>
                                                <Button
                                                  size="sm"
                                                  variant="danger"
                                                  className="status-button m-0 btn-danger"
                                                  onClick={() =>
                                                    handleDisable(slot)
                                                  }
                                                  disabled={slot.isMinted}
                                                >
                                                  Disable
                                                </Button>
                                              </div>
                                            ) : (
                                              <div className="status-container">
                                                <p className="m-0">
                                                  Slot is Inactive
                                                </p>
                                                <Button
                                                  size="sm"
                                                  className="status-button m-0 "
                                                  onClick={() =>
                                                    handleDisable(slot)
                                                  }
                                                  disabled={slot.isMinted}
                                                >
                                                  Enable
                                                </Button>
                                              </div>
                                            )}
                                          </Row>
                                        )}
                                      </CardText>
                                    </CardBody>
                                    {slot.isGiftNFT && (
                                      <div className="gift-indicator">
                                        <img src={giftIcon} alt="" width={30} />
                                      </div>
                                    )}
                                  </Card>
                                </Col>
                              ))}
                            </>
                          )}
                        </Row>

                        {/* <div className="d-flex justify-content-center bottom-buttons-container">
              <Button
                className="p-3 btn-simple"
                onClick={() => {
                  setShowNewSlot(true);
                }}
              >
                Add new slot
              </Button>
            </div> */}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        ) : (
          <h3 className="text-danger">
            You don't have access to this section.
          </h3>
        )}
      </div>
      {web3Loading && (
        <div className="nft-loader-container fullscreen topmost">
          <LoaderFood className="nft-loader" />
        </div>
      )}
    </>
  );
};

export default TimeSlots;
