/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import LoaderSpoon from "components/Loaders/LoaderSpoon";
import { useStoreState } from "easy-peasy";
import moment from "moment";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import ReactNotificationAlert from "react-notification-alert";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
  Pagination,
  PaginationItem,
  PaginationLink,
  Input,
  InputGroup,
  InputGroupAddon,
  Container,
} from "reactstrap";
import SlotsService from "services/SlotsService";

function Tables() {
  const restaurantId = useStoreState((states) => states.restaurantId);
  const headers = useStoreState((states) => states.headers);
  const [pageNo, setPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [slots, setSlots] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editAmount, setEditAmount] = useState("");
  const [editAmountSlotId, setEditAmountSlotId] = useState("0");

  const notificationAlertRef = useRef(null);

  const notify = (message = <span>Something went wrong</span>) => {
    var options = {
      place: "bc",
      message: message,
      type: "danger",
      icon: "tim-icons  icon-alert-circle-exc",
      autoDismiss: 5,
    };

    notificationAlertRef.current?.notificationAlert(options);
  };

  const fetchPastSlots = () => {
    setLoading(true);
    const startTime = moment(new Date()).unix();
    console.log(startTime);
    SlotsService.getPastMintedSlots({
      restoId: restaurantId,
      pageNo,
    })
      .then((res) => {
        setSlots(res.data.slots);
        setTotalPages(res.data.totalPages);
        setLoading(false);
      })
      .catch((e) => {
        console.error("Error fetching slots:", e);
        notify();
        setLoading(false);
      });
  };

  const handleUpdateBillAmount = async () => {
    try {
      setLoading(true);
      await SlotsService.updateBillAmount({
        id: editAmountSlotId,
        amount: editAmount,
      });

      fetchPastSlots();
      setEditAmountSlotId(0);
      setEditAmount(0);
    } catch (e) {
      setLoading(false);
      console.log(e);
      notify();
    }
  };

  const handleEditButtonClick = (id, amount) => {
    setEditAmount(amount);
    setEditAmountSlotId(id);
  };

  useEffect(() => {
    if (restaurantId && headers) fetchPastSlots();
  }, [restaurantId, headers, pageNo]);

  return (
    <>
      <div className="content past-reservations">
        <Row>
          <Col md="12">
            <Card className="position-relative">
              <CardHeader>
                <CardTitle tag="h4">Past Reservations</CardTitle>
                {/* <p className="category">Here is a subtitle for this table</p> */}
              </CardHeader>
              <CardBody>
                <Table className="tablesorter position-relative" responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>Date</th>
                      <th>Time</th>
                      <th>Token ID</th>
                      <th>Name</th>
                      <th>Wallet Address</th>
                      <th className="text-center">Seats</th>
                      <th>Used on</th>
                      <th className="text-center">Bill amount ($)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {slots.map((slot, i) => (
                      <tr>
                        <td>
                          {" "}
                          {slot.slotTime &&
                            new Date(slot.slotTime * 1000).toLocaleDateString()}
                        </td>
                        <td>
                          {slot.slotTime &&
                            new Date(slot.slotTime * 1000)
                              .toTimeString()
                              .substring(0, 5)}
                        </td>
                        <td>{slot.tokenId}</td>
                        <td>
                          {slot.tokenMetadata ? slot.tokenMetadata.name : "N/A"}
                        </td>
                        <td>
                          {slot.isMinted ? (
                            <div title={slot.walletAddress}>
                              {slot.walletAddress.substring(0, 4) +
                                "..." +
                                slot.walletAddress.substring(
                                  slot.walletAddress.length - 4
                                )}
                            </div>
                          ) : (
                            "NA"
                          )}
                        </td>
                        <td>{slot.seatCapacity}</td>
                        <td>
                          {!slot.isUtilized
                            ? "Not Used"
                            : new Date(slot.utilizedAt).toDateString() +
                              " at " +
                              new Date(slot.utilizedAt)
                                .toTimeString()
                                .substring(0, 5)}
                        </td>
                        <td className="text-center">
                          {editAmountSlotId === slot._id ? (
                            <>
                              <Input
                                type="number"
                                min={0}
                                value={editAmount}
                                onChange={(e) => {
                                  setEditAmount(e.target.value);
                                  console.log(e.target.value);
                                }}
                              />

                              <Container>
                                <Row className="buttons-container">
                                  <Col xs={6} className="p-0">
                                    <Button
                                      className="tim-icons icon-simple-remove w-100 p-2"
                                      onClick={() => setEditAmountSlotId(0)}
                                    />
                                  </Col>
                                  <Col xs={6} className="p-0">
                                    <Button
                                      className="tim-icons icon-check-2 w-100 p-2"
                                      onClick={handleUpdateBillAmount}
                                    />
                                  </Col>
                                </Row>
                              </Container>
                            </>
                          ) : (
                            <>
                              {slot.isUtilized && (
                                <>
                                  {slot.billAmount || 0}
                                  <i
                                    className="tim-icons icon-pencil ml-2 edit-icon cursor-pointer"
                                    onClick={() =>
                                      handleEditButtonClick(
                                        slot._id,
                                        slot.billAmount
                                      )
                                    }
                                  />
                                </>
                              )}
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>

                {totalPages !== 0 && (
                  <div className="pagination-container ">
                    <Pagination size="sm">
                      <PaginationItem disabled={pageNo === 1}>
                        <PaginationLink
                          previous
                          onClick={() =>
                            setPageNo((pageNo) =>
                              pageNo === 1 ? pageNo : pageNo - 1
                            )
                          }
                        />
                      </PaginationItem>

                      {Array.from(Array(totalPages).keys()).map((_, i) => (
                        <PaginationItem active={pageNo === i + 1}>
                          <PaginationLink onClick={() => setPageNo(i + 1)}>
                            {i + 1}
                          </PaginationLink>
                        </PaginationItem>
                      ))}

                      <PaginationItem disabled={pageNo === totalPages}>
                        <PaginationLink
                          next
                          onClick={() => setPageNo((pageNo) => pageNo + 1)}
                        />
                      </PaginationItem>
                    </Pagination>
                  </div>
                )}
              </CardBody>
              {loading && (
                <div className="loader-container">
                  <LoaderSpoon width={150} className="loader" />
                </div>
              )}
            </Card>
          </Col>
        </Row>
        {/* {loading && (
          <div className="loader-container">
            <LoaderSpoon width={150} className="loader" />
          </div>
        )} */}
        <div className="react-notification-alert-container">
          <ReactNotificationAlert ref={notificationAlertRef} />
        </div>
      </div>
    </>
  );
}

export default Tables;
