import axios from "axios";

const ADMIN_BASE_URL = process.env.REACT_APP_API_BASE_URL + "/restaurantAdmin";
const BASE_URL_NEW = process.env.REACT_APP_BLOCKCHAIN_BE_SERVER_URL;

class RestaurantAdminService {
  authenticate = (username, password) => {
    return axios.get(ADMIN_BASE_URL + "/authenticate", {
      auth: { username, password },
    });
  };

  authenticateAdmin = async () => {
    const accessToken = localStorage.getItem("idToken");
    const appPubKey = localStorage.getItem("appPubKey");
    if (!accessToken) alert("admin not logged in correctly");
    const response = await axios.post(`${BASE_URL_NEW}/login-resto-admin`);
    return await response.data;
  };

  addSubAdmin = (walletAddress) => {
    return axios.post(BASE_URL_NEW + "/assign-sub-admin", { walletAddress });
  };

  getSubAdmins = () => {
    return axios.get(BASE_URL_NEW + "/sub-admins");
  };

  deleteSubAdmin = (walletAddress) => {
    return axios.post(BASE_URL_NEW + "/remove-sub-admin", {
      walletAddress,
    });
  };
}

export default new RestaurantAdminService();
