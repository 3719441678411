/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

// core components
import AdminNavbar from "../../components/Navbars/AdminNavbar.js";
import Footer from "../../components/Footer/Footer.js";
import Sidebar from "../../components/Sidebar/Sidebar.js";
import FixedPlugin from "../../components/FixedPlugin/FixedPlugin.js";

import routes from "../../routes";
import error from "../../assets/img/error.webp";

import logo from "../../assets/img/paidrez-logo.png";
import { BackgroundColorContext } from "../../contexts/BackgroundColorContext";
import { useHistory } from "react-router-dom";
import { useStoreActions, useStoreState } from "easy-peasy";
import { checkIfTokenIsExpired } from "@web3auth/base";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";

var ps;

function Admin(props) {
  const location = useLocation();
  const mainPanelRef = React.useRef(null);
  const [sidebarOpened, setsidebarOpened] = React.useState(
    document.documentElement.className.indexOf("nav-open") !== -1
  );
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  const handleClose = () => {
    setLogout(true);
    setModal(!modal);
  };

  const isLoggedIn = useStoreState((states) => states.isLoggedIn);
  const setLogout = useStoreActions((action) => action.setLogout);

  const history = useHistory();
  React.useLayoutEffect(() => {
    if (!isLoggedIn) {
      console.log("not logged in");
      history.push("/login");
    }
  });

  React.useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState == "visible") {
        const idToken = localStorage.getItem("idToken");
        if (idToken) {
          try {
            const expired = checkIfTokenIsExpired(idToken);
            console.log("Token expired?:", expired);
            if (expired) {
              toggle();
            }
          } catch (e) {
            console.log("ERROR::", e);
            toggle();
          }
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () =>
      document.removeEventListener("visibilitychange", handleVisibilityChange);
  });

  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(mainPanelRef.current, {
        suppressScrollX: true,
      });
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.documentElement.classList.add("perfect-scrollbar-off");
        document.documentElement.classList.remove("perfect-scrollbar-on");
      }
    };
  });
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    if (mainPanelRef.current) {
      mainPanelRef.current.scrollTop = 0;
    }
  }, [location]);
  // this function opens and closes the sidebar on small devices
  const toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    setsidebarOpened(!sidebarOpened);
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  return (
    <BackgroundColorContext.Consumer>
      {({ color, changeColor }) => (
        <React.Fragment>
          <div className="wrapper">
            <Sidebar
              routes={routes}
              logo={{
                outterLink: "https://www.paidrez.com/",
                // text: "",
                imgSrc: logo,
              }}
              toggleSidebar={toggleSidebar}
            />
            <div className="main-panel" ref={mainPanelRef} data={color}>
              <AdminNavbar
                brandText={getBrandText(location.pathname)}
                toggleSidebar={toggleSidebar}
                sidebarOpened={sidebarOpened}
              />
              <Switch>
                {getRoutes(routes)}
                <Redirect from="*" to="/admin/dashboard" />
              </Switch>
              {
                // we don't want the Footer to be rendered on map page
                location.pathname === "/admin/maps" ? null : <Footer fluid />
              }
            </div>

            <Modal
              isOpen={modal}
              toggle={handleClose}
              backdrop={true}
              centered={true}
              fade={true}
              fullscreen={true}
              className="session-expired-modal"
            >
              <ModalBody>
                <div
                  className="close-button-container"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M13.896 0.664673H34.126C42.036 0.664673 47.3327 6.21801 47.3327 14.478V33.5437C47.3327 41.7803 42.036 47.3313 34.126 47.3313H13.896C5.98602 47.3313 0.666016 41.7803 0.666016 33.5437V14.478C0.666016 6.21801 5.98602 0.664673 13.896 0.664673ZM31.0228 30.998C31.8162 30.207 31.8162 28.9237 31.0228 28.1304L26.8695 23.977L31.0228 19.8214C31.8162 19.0304 31.8162 17.7237 31.0228 16.9304C30.2295 16.1347 28.9462 16.1347 28.1295 16.9304L23.9995 21.0814L19.8462 16.9304C19.0295 16.1347 17.7462 16.1347 16.9528 16.9304C16.1595 17.7237 16.1595 19.0304 16.9528 19.8214L21.1062 23.977L16.9528 28.107C16.1595 28.9237 16.1595 30.207 16.9528 30.998C17.3495 31.3947 17.8862 31.607 18.3995 31.607C18.9362 31.607 19.4495 31.3947 19.8462 30.998L23.9995 26.8704L28.1528 30.998C28.5495 31.4204 29.0628 31.607 29.5762 31.607C30.1128 31.607 30.6262 31.3947 31.0228 30.998Z"
                      fill="#D9D9D9"
                    />
                  </svg>
                </div>
                <img
                  src={error}
                  alt=""
                  width={200}
                  className="mx-auto d-block"
                />
                <h3 className="text-muted text-center">
                  Your Session has Expired.
                </h3>

                <p className="text-muted text-center">Please Sign In Again</p>
                {/* <Button
                  className="d-block mx-auto"
                  onClick={() => handleClose()}
                >
                  Sign In
                </Button> */}
              </ModalBody>
            </Modal>
          </div>
          {/* <FixedPlugin bgColor={color} handleBgClick={changeColor} /> */}
        </React.Fragment>
      )}
    </BackgroundColorContext.Consumer>
  );
}

export default Admin;
