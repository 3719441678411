import moment from "moment";
import React from "react";

const InviteSuccess = ({ newGift, mobileNumber = "+91 8882949886" }) => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="100"
        height="100"
        viewBox="0 0 48 48"
        className="d-block mx-auto"
      >
        <path
          fill="#c8e6c9"
          d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"
        ></path>
        <path
          fill="#4caf50"
          d="M34.586,14.586l-13.57,13.586l-5.602-5.586l-2.828,2.828l8.434,8.414l16.395-16.414L34.586,14.586z"
        ></path>
      </svg>

      <b className="mb-0 d-block text-center">
        Your invitation has been successfully sent to
      </b>
      <div className="wallet-address-container text-center">
        <b className="paidrez-color">{newGift?.mobile}</b>
      </div>

      <hr />

      <b className="mb-2 d-block">Reservation Details</b>
      <div className="reservation-details">
        <div className="img-container">
          <img src={newGift?.s3ImageURI} alt="" width={100} height={100} />
        </div>

        <div className="details-container">
          <div className="d-flex">
            <div className="detail">Token ID</div>
            <div className="value">#{newGift?.tokenId}</div>
          </div>
          <div className="d-flex">
            <div className="detail">Date</div>
            <div className="value">
              {" "}
              {moment(new Date(newGift?.slotTime * 1000)).format(
                "MMM Do YYYY, dddd"
              )}
            </div>
          </div>
          <div className="d-flex">
            <div className="detail">Time</div>
            <div className="value">
              {" "}
              {moment(new Date(newGift?.slotTime * 1000)).format("LT")}
            </div>
          </div>

          <div className="d-flex">
            <div className="detail">Guests</div>
            <div className="value">{newGift?.seatCapacity}</div>
          </div>
        </div>
      </div>

      <hr />

      <small>
        <b>
          A unique link has been sent to {newGift?.mobile} via SMS. Reservation
          will be transferred to their wallet once they accept the invite.
        </b>
      </small>
    </div>
  );
};

export default InviteSuccess;
