import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";
import "../assets/scss/gift-page.scss";
import GiftSuccess from "./popups/GiftSuccess";
import { useStoreState } from "easy-peasy";
import RestaurantService from "services/RestaurantService";
import ReactNotificationAlert from "react-notification-alert";
import LoaderSpoon from "components/Loaders/LoaderSpoon";
import LoaderFood from "components/Loaders/LoaderFood";
import SlotsService from "services/SlotsService";
import moment from "moment";
import InviteAddNew from "./popups/InviteAddNew";
import InviteConfirm from "./popups/InviteConfirm";
import InviteSuccess from "./popups/InviteSuccess";

const Invites = () => {
  const [restaurant, setRestaurant] = useState(null);
  const [modal, setModal] = useState(false);
  const [modalNumber, setModalNumber] = useState(-1);
  const [invites, setInvites] = useState([]);
  const [newInvite, setNewInvite] = useState(null);
  // const [inviteObj, setInviteObj] = useState(null);
  const [modalLoader, setModalLoader] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [copiedKey, setCopiedKey] = useState(-1);
  const [past, setPast] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(8);
  const [mobile, setMobile] = useState("");
  const restaurantId = useStoreState((states) => states.restaurantId);
  const role = useStoreState((state) => state.role);
  const headers = useStoreState((states) => states.headers);
  const account = useStoreState((states) => states.account);

  const notificationAlertRef = useRef(null);

  useEffect(() => {
    fetchRestaurantDetails();
  }, []);

  useEffect(() => {
    if (role === "RESTO_ADMIN" && headers) {
      fetchInvites();
    }
  }, [headers, role, past, pageNo]);

  useEffect(() => {
    if (modalNumber === -1) {
      setModal(false);
    } else {
      setModal(true);
    }
  }, [modalNumber]);

  const notify = (message = <span>Something went wrong</span>) => {
    var options = {
      place: "bc",
      message: message,
      type: "danger",
      icon: "tim-icons  icon-alert-circle-exc",
      autoDismiss: 5,
    };

    notificationAlertRef.current?.notificationAlert(options);
  };

  const closeModal = () => {
    setModalNumber(-1);
  };

  const fetchInvites = async () => {
    setLoading(true);
    try {
      let params;

      if (past) {
        params = { pageNo, pageSize, endTime: new moment().unix() };
      } else {
        params = {
          pageNo,
          pageSize,
          startTime: new moment().unix(),
          sortOrder: "ASC",
        };
      }

      // const temp = await SlotsService.getInvites();
      // console.log(temp);

      const res = await SlotsService.getInviteSlots(params);
      console.log(res);
      setInvites(res.data?.slots);
      setTotalPages(res.data?.totalPages);
      setLoading(false);
    } catch (e) {
      notify("Couldn't fetch invites");
      setLoading(false);
    }
  };

  const fetchRestaurantDetails = () => {
    console.log("fetch restaurant details");
    RestaurantService.getRestaurantById(restaurantId)
      .then((res) => {
        console.log("restaurant:", res.data);
        setRestaurant(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleSendGiftNow = (gift) => {
    setNewInvite(gift);
    setModalNumber(1);
  };

  const handleInviteSent = (mobile) => {
    setMobile(mobile);
    setModalNumber(2);
  };

  return (
    <div className="content gift-page">
      <Button onClick={() => setModalNumber(0)} className="paidrez-btn">
        New Invite
      </Button>
      <br />
      <Row className="mx-0 top-buttons">
        <Button
          onClick={() => setPast(false)}
          className={
            past
              ? "paidrez-btn-secondary col mx-0 left"
              : "paidrez-btn col mx-0 left"
          }
        >
          Upcoming Invites
        </Button>
        <Button
          onClick={() => setPast(true)}
          className={
            !past
              ? "paidrez-btn-secondary col mx-0 right"
              : "paidrez-btn col mx-0 right"
          }
        >
          Past Invites
        </Button>
      </Row>

      {loading && invites.length === 0 ? (
        <LoaderSpoon width={150} className="d-block mx-auto" />
      ) : (
        <div className="position-relative">
          {loading && invites.length > 0 && (
            <div className="w-100 h-100 position-absolute bg-light d-flex justify-content-center align-items-center">
              <LoaderSpoon width={150} />
            </div>
          )}
          <Table className="tablesorter" responsive>
            <thead className="text-primary whitespace-nowrap">
              <tr>
                <th>Date</th>
                <th>Time</th>
                <th>Token Id</th>
                <th className="text-center">Seats</th>
                <th>Wallet Address</th>
                <th>Name</th>
                <th>Mobile</th>
                <th>Status</th>
                {/* <th>Contact</th> */}
              </tr>
            </thead>

            <tbody className="whitespace-nowrap">
              {invites.map((slot, i) => (
                <tr key={i}>
                  <td>
                    {slot.slotTime &&
                      new Date(slot.slotTime * 1000).toLocaleDateString()}
                  </td>
                  <td>
                    {slot.slotTime &&
                      new Date(slot.slotTime * 1000)
                        .toTimeString()
                        .substring(0, 5)}
                  </td>
                  <td>{slot.tokenId}</td>
                  <td className="text-center">{slot.seatCapacity}</td>
                  <td>
                    {slot.ownerData?.walletAddress && (
                      <>
                        <>
                          {slot.ownerData.walletAddress.substring(0, 4) +
                            " . . . " +
                            slot.ownerData.walletAddress.substring(
                              slot.ownerData.walletAddress.length - 4
                            )}
                        </>

                        <div className="copy-button ms-1">
                          {copiedKey === i ? (
                            <svg viewBox="0 0 24 24" className="copy-success">
                              <path
                                fill="currentColor"
                                d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"
                              ></path>
                            </svg>
                          ) : (
                            <svg
                              viewBox="0 0 24 24"
                              className="copy"
                              onClick={() => {
                                setCopiedKey(i);
                                setTimeout(() => setCopiedKey(-1), 3000);
                              }}
                            >
                              <path
                                fill="currentColor"
                                d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z"
                              ></path>
                            </svg>
                          )}
                        </div>
                      </>
                    )}
                  </td>

                  <td>{slot.invite?.inviteeName}</td>

                  <td>{slot.invite?.mobile}</td>
                  <td>{slot.invite?.status}</td>
                  {/* <td>
                {
                  <div title={slot.walletAddress}>
                    {
                      slot.walletAddress
                      // .substring(0, 4) +
                      //   "..." +
                      //   slot.walletAddress.substring(
                      //     slot.walletAddress.length - 4
                      //   )
                    }
                  </div>
                }
              </td> */}

                  <td>
                    <Button
                      className=" px-3 paidrez-btn btn-sm"
                      disabled={slot.invite?.status === "ACCEPTED"}
                      onClick={() => handleSendGiftNow(slot)}
                    >
                      Send Invite
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {!loading && invites.length === 0 && (
            <p className="text-center">
              <b>No More Invites</b>
            </p>
          )}
        </div>
      )}

      {totalPages !== 0 && (
        <div className="pagination-container ">
          <Pagination size="sm">
            <PaginationItem disabled={pageNo === 1}>
              <PaginationLink
                previous
                onClick={() =>
                  setPageNo((pageNo) => (pageNo === 1 ? pageNo : pageNo - 1))
                }
              />
            </PaginationItem>

            {Array.from(Array(totalPages).keys()).map((_, i) => (
              <PaginationItem active={pageNo === i + 1}>
                <PaginationLink onClick={() => setPageNo(i + 1)}>
                  {i + 1}
                </PaginationLink>
              </PaginationItem>
            ))}

            <PaginationItem disabled={pageNo === totalPages}>
              <PaginationLink
                next
                onClick={() => setPageNo((pageNo) => pageNo + 1)}
              />
            </PaginationItem>
          </Pagination>
        </div>
      )}

      <Modal
        isOpen={modal}
        toggle={closeModal}
        backdrop={true}
        centered={true}
        fade={true}
        fullscreen={true}
        className={
          "gift-modal " +
          {
            0: "add-new",
            1: "confirm-gift",
            2: "gift-sent",
          }[modalNumber] +
          " invite-modal"
        }
      >
        <ModalHeader toggle={closeModal} className="text-center">
          <h4 className="mb-0">
            {
              {
                0: "New Invite",
                1: "Confirm Invite",
                2: "Success",
              }[modalNumber]
            }
          </h4>
        </ModalHeader>
        <ModalBody>
          {
            {
              0: (
                <InviteAddNew
                  setModalNumber={setModalNumber}
                  closeModal={closeModal}
                  restoId={restaurant?.restoId}
                  notify={notify}
                  setModalLoader={setModalLoader}
                  setLoadingMessage={setLoadingMessage}
                  setNewGift={setNewInvite}
                />
              ),
              1: (
                <InviteConfirm
                  setModalNumber={setModalNumber}
                  closeModal={closeModal}
                  notify={notify}
                  setModalLoader={setModalLoader}
                  setLoadingMessage={setLoadingMessage}
                  newGift={newInvite}
                  fetchInvites={fetchInvites}
                  handleInviteSent={handleInviteSent}
                />
              ),
              2: <InviteSuccess newGift={{ ...newInvite, mobile }} />,
            }[modalNumber]
          }
        </ModalBody>
      </Modal>

      {modalLoader && (
        <div className="nft-loader-container fullscreen topmost">
          <LoaderFood className="loader" />
          <h4 className="text-center">{loadingMessage}</h4>
        </div>
      )}

      <div className="react-notification-alert-container">
        <ReactNotificationAlert ref={notificationAlertRef} />
      </div>
    </div>
  );
};

export default Invites;
