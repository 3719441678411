import React from "react";
import { Button, Col, Row } from "reactstrap";

const WhitelistResetConfirmation = ({ closeModal, handleReset, loader }) => {
  return (
    <div>
      <h4>
        Are you sure to reset whitelist? All the wallet addresses will be
        removed.
      </h4>
      <h4 className="text-danger">
        <b>You will not be able to undo this action.</b>
      </h4>

      <Row>
        <Col>
          <Button
            className="btn-danger w-100"
            onClick={handleReset}
            disabled={loader}
          >
            Reset
          </Button>
        </Col>
        <Col>
          <Button className="w-100" onClick={closeModal} disabled={loader}>
            Cancel
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default WhitelistResetConfirmation;
