import axios from "axios";

const WHITELIST_URL =
  process.env.REACT_APP_BLOCKCHAIN_BE_SERVER_URL + "/whitelist";

export const getWhitelist = (restaurantId) => {
  return axios.get(WHITELIST_URL + "/" + restaurantId);
};

export const toggleWhitelistStatus = (restaurantId) => {
  return axios.post(WHITELIST_URL + "/toggle-status/" + restaurantId);
};

export const removeFromWhitelist = (restaurantId, addressesArray) => {
  return axios.post(WHITELIST_URL + "/remove", {
    id: restaurantId,
    addresses: addressesArray,
  });
};

export const addToWhitelist = (restaurantId, walletAddressArray) => {
  return axios.post(WHITELIST_URL + "/add", {
    id: restaurantId,
    addresses: walletAddressArray,
  });
};

export const addToWhitelistCSV = (restaurantId, whitelistFile) => {
  const form = new FormData();
  form.append("whitelist", whitelistFile);
  return axios.post(WHITELIST_URL + "/upload/" + restaurantId, form);
};

export const resetWhitelist = (restaurantId) => {
  return axios.post(WHITELIST_URL + "/remove-all", { id: restaurantId });
};
