import axios from "axios";
import { ethers } from "ethers";

const BASE_URL_NEW = process.env.REACT_APP_BLOCKCHAIN_BE_SERVER_URL;

class SlotsService {
  addSlot(slot) {
    const data = new FormData();
    for (var key in slot) {
      data.append(key, slot[key]);
    }

    // for (var pair of data.entries()) {
    //   console.log(pair[0] + ", " + pair[1]);
    // }

    return axios.post(BASE_URL_NEW + "/add-slot", data);
  }

  confirmSlot(_id) {
    return axios.post(BASE_URL_NEW + "/confirm-slot/" + _id);
  }

  getSlots({ restoId, startTime, endTime, seatCapacity, pageNo, pageSize }) {
    return axios.get(BASE_URL_NEW + "/reservation-slots", {
      params: { restoId, startTime, endTime, seatCapacity, pageNo, pageSize },
    });
  }

  getSlotById(id) {
    return axios.get(BASE_URL_NEW + "/reservation/" + id);
  }

  getUpcomingMintedSlots(params) {
    return axios.get(BASE_URL_NEW + "/upcoming-reserved-slots", {
      params: params,
    });
  }

  getPastMintedSlots(params) {
    return axios.get(BASE_URL_NEW + "/past-reserved-slots", { params });
  }

  markAsUtilized(tokenId) {
    return axios.post(BASE_URL_NEW + "/mark-as-utilized", { tokenId });
  }

  deleteSlot(slotId) {
    return axios.delete(BASE_URL_NEW + "/delete-slot/" + slotId);
  }

  toggleSlotStatus(restaurantId, slotId) {
    return axios.post(
      BASE_URL_NEW + "/toggle-slot-status/" + restaurantId + "/" + slotId
    );
  }

  updateBillAmount({ id, amount }) {
    return axios.post(BASE_URL_NEW + "/update-bill-amount", { id, amount });
  }

  getGifts(params) {
    return axios.get(BASE_URL_NEW + "/gift-slots", { params });
  }

  getInvites(params) {
    return axios.get(BASE_URL_NEW + "/invites", { params });
  }

  getInviteSlots(params) {
    return axios.get(BASE_URL_NEW + "/invite-slots", { params });
  }

  sendInvite(params) {
    return axios.post(BASE_URL_NEW + "/send-invite", params);
  }

  updateInvite(params) {
    return axios.post(BASE_URL_NEW + "/update-invite", params);
  }
}

export default new SlotsService();
