import React, { useState } from "react";
import { Button, Form, FormGroup, FormText, Input } from "reactstrap";
import RestaurantAdminService from "services/RestaurantAdminService";

const AddSubAdmin = ({ toggleModal, fetchSubAdmins }) => {
  const [walletAddress, setWalletAddress] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleError = (message) => {
    setError(message);
    // setTimeout(() => setError(""), 2000);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    console.log(walletAddress);
    if (walletAddress.length !== 42) {
      handleError("Invalid Wallet Address");
      setLoading(false);
      return;
    }

    RestaurantAdminService.addSubAdmin(walletAddress)
      .then((res) => {
        console.log(res);
        fetchSubAdmins();
        toggleModal();
      })
      .catch((e) => {
        console.log(e);
        if (e.response?.data?.errStatus === 500) {
          handleError(e.response?.data?.message);
        }
        setLoading(false);
      });
  };

  return (
    <Form onSubmit={(e) => handleSubmit(e)}>
      <FormGroup>
        <FormText className="mb-2">Enter Wallet Address of Sub Admin</FormText>
        <Input
          className="mb-2"
          value={walletAddress}
          onChange={(e) => setWalletAddress(e.target.value)}
        />
        <Button type="submit" disabled={loading}>
          Submit
        </Button>
      </FormGroup>

      {error !== "" && (
        <b className="text-danger">
          <i className="tim-icons icon-alert-circle-exc mr-1 font-weight-bolder" />
          {error.charAt(0).toUpperCase() + error.substring(1)}
        </b>
      )}
    </Form>
  );
};

export default AddSubAdmin;
