import { createStore, action, persist } from "easy-peasy";

const MainStorage = createStore(
  persist(
    {
      contractInstance: null,
      // localStorage.getItem("contractInstance")
      //   ? JSON.parse(localStorage.getItem("contractInstance"))
      // :

      setContractInstance: action((state, payload) => {
        state.contractInstance = payload;
      }),

      provider: null,
      setProvider: action((state, payload) => {
        state.provider = payload;
      }),

      balance: null,
      setBalance: action((state, payload) => {
        state.balance = payload;
      }),

      account: null,
      setAccount: action((state, payload) => {
        state.account = payload;
      }),

      isLoggedIn: false,
      setLoggedIn: action((state, payload) => {
        state.isLoggedIn = payload;
      }),

      restaurantId: null,
      setRestaurantId: action((state, payload) => {
        state.restaurantId = payload;
      }),

      headers: false,
      setHeaders: action((state, payload) => {
        state.headers = payload;
      }),

      role: null,
      setRole: action((state, payload) => {
        state.role = payload;
      }),

      logout: false,
      setLogout: action((state, payload) => {
        state.logout = payload;
      }),

      sessionExpired: false,
      setSessionExpired: action((state, payload) => {
        state.sessionExpired = payload;
      }),
    },

    {
      allow: [
        "contractInstance",
        "provider",
        "isLoggedIn",
        "restaurantId",
        "account",
        "role",
      ],
      storage: "localStorage",
    }
  )
);

export default MainStorage;
