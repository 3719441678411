import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-horizontal-datepicker";
import TimeInput from "react-time-picker-input";
import { Button, Form, FormGroup, Input, Label, Row } from "reactstrap";
import SlotsService from "services/SlotsService";
import "../../assets/scss/invites.scss";

const InviteAddNew = ({
  setModalNumber,
  closeModal,
  restoId,
  notify,
  setModalLoader,
  setLoadingMessage,
  setNewGift,
}) => {
  const [date, setDate] = useState(new Date());
  const [newTime, setNewTime] = useState("10:15");
  const [newSlotPaxCount, setNewSlotPaxCount] = useState(2);
  const [newNftImage, setNewNFTImage] = useState(null);
  const [preview, setPreview] = useState();
  const [chooseModal, setChooseModal] = useState(false);

  const imageInputRef = useRef();

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!newNftImage) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(newNftImage);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [newNftImage]);

  const selectedDay = (val) => {
    setDate(val);
  };

  const nftImageInputFunction = () => {
    imageInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    setNewNFTImage(event.target.files[0]);
    console.log(event.target.files[0]);
  };

  const confirmSlot = async (id) => {
    setLoadingMessage("Confirming Reservation");
    const res = await SlotsService.confirmSlot(id);
    console.log(res);
    return res.data?.result;
  };

  const handleAdd = async () => {
    if (!newNftImage) {
      notify("Please select NFT image");
      return;
    }

    setLoadingMessage("Adding Reservation");
    setModalLoader(true);

    const slotTime = moment(date)
      .set({
        hour: newTime.substring(0, 2),
        minute: newTime.substring(3, 5),
        second: "00",
      })
      .toString();

    // --- Solution For multiple time zones, has bugs ---
    // const splitDate = date.toISOString().split("T");
    // const slotTime = splitDate[0] + "T" + newTime + splitDate[1].substring(5);

    console.log(slotTime);

    let epoch = Math.floor(new Date(slotTime) / 1000);

    const slot = {
      restoId: restoId,
      nftImage: newNftImage,
      slotTime: epoch,
      seatCapacity: newSlotPaxCount,
      duration: 1,
      price: "0",
      isInviteNFT: true,
    };

    try {
      const res = await SlotsService.addSlot(slot);
      console.log(res);

      const confirmedSlot = await confirmSlot(res.data?.result?._id);
      setNewGift(confirmedSlot);
      setModalLoader(false);
      setModalNumber(1);
    } catch (e) {
      setModalLoader(false);
      notify();
      console.log(e);
    }
  };

  return (
    <>
      <div
        className={
          chooseModal ? "add-new-gift-popup less-opacity" : "add-new-gift-popup"
        }
      >
        <div className="datepicker-container">
          <DatePicker
            getSelectedDay={selectedDay}
            selectDate={new Date(date)}
            labelFormat={"MMMM"}
            // color="rgb(10,105,126)"
            color="#0c8ba7"
            className="mb-2"
          />
        </div>

        <div className="new-slots-container">
          <Form className="text-center number-of-slots-container">
            <FormGroup className="col px-0 mb-0">
              <Label>Time</Label>
              <TimeInput
                value={newTime}
                hour24Format
                eachInputDropdown
                manuallyDisplayDropdown
                onChange={(dateString) => {
                  setNewTime(dateString);
                  console.log(dateString);
                }}
                className="mx-2"
              />
            </FormGroup>
            <FormGroup className="col px-0 mb-0">
              <Label>Number of guests</Label>
              <Input
                type="select"
                onChange={(e) => {
                  setNewSlotPaxCount(Number(e.target.value));
                }}
              >
                {/* <option>Number of guests</option> */}
                <option value="2">2</option>
                <option value="4">4</option>
                <option value="6">6</option>
              </Input>
            </FormGroup>
          </Form>
          <div className="nft-image-options">
            {newNftImage ? (
              <div className="image-preview-container">
                <img src={preview} alt="" width={100} />
                <div>
                  <p className="image-name">
                    {<small>File chosen: {newNftImage.name}</small>}
                  </p>
                  <Button
                    size="sm paidrez-btn"
                    onClick={(e) => {
                      e.preventDefault();
                      nftImageInputFunction();
                    }}
                  >
                    Change
                  </Button>
                </div>
              </div>
            ) : (
              <div
                className="add-image-container"
                onClick={nftImageInputFunction}
              >
                <svg
                  width="46"
                  height="45"
                  viewBox="0 0 46 45"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="mb-2"
                >
                  <path
                    d="M3.03076 32.1392L14.4775 20.6924C16.4271 18.7428 19.588 18.7428 21.5376 20.6924L32.9844 32.1392M27.9921 27.1469L31.9504 23.1886C33.9 21.239 37.061 21.2389 39.0106 23.1886L42.9689 27.1469M27.9921 12.1701H28.0171M8.02303 42.1237H37.9766C40.7338 42.1237 42.9689 39.8886 42.9689 37.1314V7.17781C42.9689 4.42066 40.7338 2.18555 37.9766 2.18555H8.02303C5.26588 2.18555 3.03076 4.42066 3.03076 7.17781V37.1314C3.03076 39.8886 5.26588 42.1237 8.02303 42.1237Z"
                    stroke="#0E91AE"
                    strokeWidth="4.10134"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <p className="mb-0">
                  <span>Upload a file</span> or drag and drop
                </p>
                <p className="text-black-50 mb-0">
                  {" "}
                  <small> PNG, JPG, GIF upto 5MB</small>
                </p>
              </div>
            )}
            <input
              type="file"
              name="nft-image-input"
              accept=".png, .jpg, .jpeg, .avif, .webp"
              onChange={handleFileChange}
              ref={imageInputRef}
              className={"d-none"}
            />
          </div>{" "}
        </div>

        <hr />

        <Row className="mx-0">
          <Button className="paidrez-btn col " onClick={handleAdd}>
            Confirm
          </Button>
          <Button className="paidrez-btn-secondary col" onClick={closeModal}>
            Cancel
          </Button>
        </Row>
      </div>
    </>
  );
};

export default InviteAddNew;
