import axios from "axios";
import { useStoreState } from "easy-peasy";
import { ethers } from "ethers";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Button, Col, Input, Row, Table } from "reactstrap";
import { markNFTAsMinted } from "services/BlockchainBackendService";
import { getCurrentMaticUSDPrice } from "services/MaticPriceService";
import "react-loading-skeleton/dist/skeleton.css";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import SlotsService from "services/SlotsService";

const InviteConfirm = ({
  closeModal,
  setModalLoader,
  setLoadingMessage,
  newGift,
  notify,
  fetchInvites,
  handleInviteSent,
}) => {
  const [gasFee, setGasFee] = useState(null);
  const [maticPrie, setMaticPrice] = useState("");
  const [gasError, setGasError] = useState(false);
  const [mobile, setMobile] = useState();
  const [name, setName] = useState("");
  const contractInstance = useStoreState((state) => state.contractInstance);
  const account = useStoreState((state) => state.account);

  useEffect(() => {
    if (contractInstance) {
      estimateGasFee();
      fetchMaticPrice();
    }
  }, [contractInstance]);

  useEffect(() => {
    console.log(mobile);
    console.log(isPossiblePhoneNumber(String(mobile)));
  }, [mobile]);

  useEffect(() => {
    console.log(newGift);
  }, [newGift]);

  const fetchMaticPrice = async () => {
    const res = await getCurrentMaticUSDPrice(1);
    console.log("price:", res);
    setMaticPrice(res);
  };

  const handleSubmit = async () => {
    if (!name) {
      notify("Please Enter the Name of the Recepient");
      return;
    }

    if (!isPossiblePhoneNumber(String(mobile))) {
      notify("Please enter a valid mobile number");
      return;
    }

    setModalLoader(true);

    try {
      if (!newGift.isMinted) {
        setLoadingMessage("Minting your Reservation");
        const transaction = await contractInstance.mint(newGift.voucher, {
          gasLimit: 600000,
          value: newGift.price,
        });

        const res = await transaction.wait();
        console.log("MINTED:", res);

        await markNFTAsMinted(newGift._id);
        console.log("Marked as minted");

        // const updated = await SlotsService.updateInvite({
        //   inviteId: newGift?.invite?._id,
        //   restoWalletAddress: account,
        // });

        // console.log(updated);
      }

      setLoadingMessage("Approving the Reservation");

      const to = process.env.REACT_APP_PAIDREZ_ADDRESS;
      const tokenId = newGift.tokenId;

      console.log("tokenid:", newGift.tokenId);
      console.log("To: ", to);

      const approve = await contractInstance.approve(to, tokenId);

      const res_approve = await approve.wait();
      console.log("Approved", res_approve);

      setLoadingMessage("Sending the Invite");
      const res_send = await SlotsService.sendInvite({
        name,
        mobile,
        inviteId: newGift?.invite?._id,
      });

      console.log(res_send);

      const updated = await SlotsService.updateInvite({
        inviteId: newGift?.invite?._id,
        restoWalletAddress: account,
      });

      console.log(updated);

      fetchInvites();
      setModalLoader(false);
      handleInviteSent(mobile);
    } catch (e) {
      console.log(e);
      setModalLoader(false);
      notify("Something Went Wrong");
    }
  };

  const estimateGasFee = async () => {
    const methodName = "mint";
    const methodArgs = [newGift.voucher];

    const gas_fee_url = process.env.REACT_APP_GAS_FEES_URL;

    try {
      // throw new Error("e");
      let gasPriceInfo = await fetch(gas_fee_url)
        .then((response) => response.json())
        .catch((e) => {
          console.log("Error fetching gas price:", e);
          // handleModalError();
        });

      console.log("gas price info:", gasPriceInfo);

      const options = {
        gasPrice: ethers.utils.parseUnits(
          gasPriceInfo.fast.maxFee.toFixed(6),
          "gwei"
        ), // Set your desired gas price
        value: newGift.price,
      };

      let gasEstimate;

      if (!newGift.isMinted) {
        gasEstimate = await contractInstance.estimateGas[methodName](
          ...methodArgs, // equivalent to nft.voucher (not array, just the value)
          options
        );
      } else {
        console.log("estimating for approve only");
        const to = process.env.REACT_APP_PAIDREZ_ADDRESS;
        const tokenId = newGift.tokenId;

        gasEstimate = await contractInstance.estimateGas.approve(to, tokenId);
      }

      console.log(gasEstimate);

      let gasFee = ethers.utils
        .formatUnits(gasEstimate.mul(options.gasPrice), "ether")
        .substring(0, 10);

      gasFee = Number(gasFee).toFixed(5);

      setGasFee(gasFee);

      // const _priceInUSD = await getCurrentMaticUSDPrice(gasFee);
      // setPriceInUSD(_priceInUSD);

      console.log("Gas estimate:", gasEstimate.toString());
      console.log("Estimated gas fees:", gasFee);
    } catch (e) {
      console.log("ERROR::", e);
      setGasError(true);
    }
  };

  return (
    <div>
      <div className="reservation-details">
        <div className="img-container">
          <img
            src="https://paidrez.s3.us-east-2.amazonaws.com/PaidRez-0xc76814f67e3e8e66722f4dcb142fb26dfdc9160a4be0c4d6149236d38f458eab"
            alt=""
            width={100}
            height={100}
          />
        </div>

        <div className="details-container">
          <div className="d-flex">
            <div className="detail">Token ID</div>
            <div className="value">#{newGift?.tokenId}</div>
          </div>
          <div className="d-flex">
            <div className="detail">Date</div>
            <div className="value">
              {moment(new Date(newGift?.slotTime * 1000)).format(
                "MMM Do YYYY, dddd"
              )}
            </div>
          </div>
          <div className="d-flex">
            <div className="detail">Time</div>
            <div className="value">
              {moment(new Date(newGift?.slotTime * 1000)).format("LT")}
            </div>
          </div>

          <div className="d-flex">
            <div className="detail">Guests</div>
            <div className="value">{newGift?.seatCapacity}</div>
          </div>
        </div>
      </div>

      <p>Sending to </p>
      <p>
        <small>Name</small>
      </p>
      <Input
        value={name}
        onChange={(e) => setName(e.target.value)}
        className="name-input mb-2"
      />
      <p>
        <small>Mobile Number</small>
      </p>
      <PhoneInput
        placeholder="Enter phone number"
        defaultCountry="US"
        international
        withCountryCallingCode
        value={mobile}
        onChange={setMobile}
        className="mobile-input"
      />

      <hr />

      <div className="mb-3">
        <div className="d-flex justify-content-between">
          <p>Estimated Gas Fee</p>
          <div>
            <big>
              <b className="paidrez-color">
                {!gasFee && !gasError ? (
                  <Skeleton height={25} width={150} />
                ) : (
                  <>{newGift.isMinted ? gasFee : gasFee * 2} MATIC </>
                )}
              </b>
            </big>

            {/* <br /> */}
            <p className="text-right">
              <b className="paidrez-color">
                {!gasFee && !gasError ? (
                  <Skeleton height={20} width={125} />
                ) : (
                  (newGift.isMinted
                    ? gasFee * maticPrie
                    : gasFee * maticPrie * 2
                  ).toFixed(6) + " USD"
                )}
              </b>
            </p>
          </div>
        </div>
        {gasError && (
          <b>
            Couldn't estimate gas required for this transaction. You can still
            continue with the transaction.
          </b>
        )}
      </div>

      <Row className="text-center">
        <small className="mb-1 text-start">
          <b>
            By confirming, you allow PaidRez to transfer this invite to the
            user's account on your behalf, when they accept the invite via SMS
            link.
          </b>
          {/* <br /> Note: Anyone with the unique link can accept and the invite
          will be transferred to their account. */}
        </small>
        <Button
          className="paidrez-btn col"
          onClick={() => {
            handleSubmit();
            // setModalNumber(4);
          }}
          disabled={!newGift}
        >
          Confirm
        </Button>
        <Button className="paidrez-btn-secondary col" onClick={closeModal}>
          Reject
        </Button>
      </Row>
    </div>
  );
};

export default InviteConfirm;
