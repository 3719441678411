import axios from "axios";
import LoaderFood from "components/Loaders/LoaderFood";
import { useStoreState } from "easy-peasy";
import React, { useEffect, useRef, useState } from "react";
import RUG from "react-upload-gallery";
import "react-upload-gallery/dist/style.css";
import { Button } from "reactstrap";
import RestaurantService from "services/RestaurantService";

const initialState = [
  {
    name: "Restaurant",
    size: "232kb",
    source:
      "https://images.unsplash.com/photo-1414235077428-338989a2e8c0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
    // "https://images.unsplash.com/photo-1549880338-65ddcdfd017b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=4050&q=80",
  },
  {
    name: "Couch",
    size: "23kb",
    source:
      "https://images.unsplash.com/photo-1537047902294-62a40c20a6ae?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=435&q=80",
    // "https://images.unsplash.com/photo-1508923567004-3a6b8004f3d7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1834&q=80",
  },
  {
    name: "Window view",
    size: "222kb",
    source:
      "https://images.unsplash.com/photo-1554679665-f5537f187268?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
  },
  {
    name: "Bar",
    size: "222kb",
    source:
      "https://images.unsplash.com/photo-1514933651103-005eec06c04b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80",
  },
  {
    name: "Couch",
    size: "222kb",
    source:
      "https://images.unsplash.com/photo-1585518419759-7fe2e0fbf8a6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1024&q=80",
  },
  {
    name: "Girl eating",
    size: "222kb",
    source:
      "https://images.unsplash.com/photo-1533777857889-4be7c70b33f7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
  },
];

const UploadImage = () => {
  const restaurantId = useStoreState((states) => states.restaurantId);
  const role = useStoreState((states) => states.role);
  const image_url = process.env.REACT_APP_API_BASE_URL + "/images/";
  const images = [{ source: image_url + restaurantId + ".jpeg" }];

  const [loading, setLoading] = useState(true);
  const [imageUploading, setImageUploading] = useState(false);
  const [imageExists, setImageExists] = useState(false);
  const [oldImage, setOldImage] = useState(null);
  const [newImage, setNewImage] = useState(null);
  const [preview, setPreview] = useState();

  const imageInputRef = useRef();

  const fetchRestaurantData = () => {
    setLoading(true);
    console.log("fetching restaurant data");
    RestaurantService.getRestaurantById(restaurantId)
      .then((res) => {
        console.log(res);
        if (res.data.displayImageS3Url) {
          setImageExists(true);
          setOldImage(res.data.displayImageS3Url);
        }

        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (role === "RESTO_ADMIN") {
      fetchRestaurantData();
    }
  }, []);

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!newImage) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(newImage);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [newImage]);

  const imageInputFunction = () => {
    imageInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    setNewImage(event.target.files[0]);
    console.log(event.target.files[0]);
  };

  const handleImageUpload = () => {
    setImageUploading(true);
    RestaurantService.updateRestaurantImage(restaurantId, newImage)
      .then((res) => {
        console.log(res);
        setNewImage(null);
        setOldImage(null);
        setImageUploading(false);
        fetchRestaurantData();
      })
      .catch((e) => {
        console.log(e);
        setImageUploading(false);
      });
  };

  return (
    <>
      <div className="content upload-image">
        <h3> Restaurant Image</h3>
        {/* <RUG
        // action={`${process.env.REACT_APP_API_BASE_URL}/restaurants/addRestaurantImage/${restaurantId}`}
        action={`${process.env.REACT_APP_BLOCKCHAIN_BE_SERVER_URL}/update-restaurant-img/${restaurantId}`}
        // source={(response) => `http://example.com/${response.source}`}
        source={(response) => image_url + response.source}
        initialState={images}
        // onConfirmDelete={(currentImage, images) => {
        onConfirmDelete={() => {
          return window.confirm("Are you sure you want to delete?");
        }}
        onDeleted={() => {
          axios.delete(
            process.env.REACT_APP_API_BASE_URL +
              "/restaurants/deleteRestaurantImage/" +
              restaurantId
          );
        }}
        onError={(response) => {
          console.log(response);
        }}
      /> */}
        {role === "RESTO_ADMIN" ? (
          <>
            {!loading && (
              <div className="mt-2">
                {imageExists && !newImage && (
                  <div>
                    <img
                      src={oldImage}
                      alt="retaurant"
                      width={400}
                      className="d-block m-auto"
                    />
                    <br />
                    <Button
                      size="sm"
                      onClick={(e) => {
                        e.preventDefault();
                        imageInputFunction();
                      }}
                      className="m-auto d-block"
                    >
                      Change
                    </Button>
                  </div>
                )}

                <div className="new-image-options">
                  {newImage && (
                    <div className="image-preview-container">
                      <img
                        src={preview}
                        alt=""
                        width={400}
                        className="d-block m-auto"
                      />
                      <br />
                      <p className="image-name text-center">
                        {<small>File chosen: {newImage.name}</small>}
                      </p>
                      <Button
                        size="sm"
                        onClick={(e) => {
                          e.preventDefault();
                          imageInputFunction();
                        }}
                        className="m-auto d-block"
                      >
                        Change
                      </Button>
                    </div>
                  )}

                  {!imageExists && !newImage && (
                    <div
                      className="add-image-container text-center mt-3"
                      onClick={imageInputFunction}
                      style={{ cursor: "pointer" }}
                    >
                      <svg
                        width="46"
                        height="45"
                        viewBox="0 0 46 45"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="mb-3"
                      >
                        <path
                          d="M3.03076 32.1392L14.4775 20.6924C16.4271 18.7428 19.588 18.7428 21.5376 20.6924L32.9844 32.1392M27.9921 27.1469L31.9504 23.1886C33.9 21.239 37.061 21.2389 39.0106 23.1886L42.9689 27.1469M27.9921 12.1701H28.0171M8.02303 42.1237H37.9766C40.7338 42.1237 42.9689 39.8886 42.9689 37.1314V7.17781C42.9689 4.42066 40.7338 2.18555 37.9766 2.18555H8.02303C5.26588 2.18555 3.03076 4.42066 3.03076 7.17781V37.1314C3.03076 39.8886 5.26588 42.1237 8.02303 42.1237Z"
                          stroke="#0E91AE"
                          strokeWidth="4.10134"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>

                      <p>
                        <span>Click to upload a file</span>
                      </p>
                      <p className="text-black-50">
                        {" "}
                        <small> PNG, JPG, GIF upto 5MB</small>
                      </p>
                    </div>
                  )}

                  <input
                    type="file"
                    name="nft-image-input"
                    accept=".png, .jpg, .jpeg"
                    onChange={handleFileChange}
                    ref={imageInputRef}
                    className={"d-none"}
                  />

                  {newImage && (
                    <div className="mx-2 mt-4 text-center">
                      <Button className="" onClick={handleImageUpload}>
                        Save
                      </Button>
                      <Button
                        className="btn-danger"
                        onClick={() => {
                          setNewImage(null);
                          // setShowNewSlot(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            )}
          </>
        ) : (
          <h3 className="text-danger">
            You don't have access to this section.
          </h3>
        )}
      </div>
      {imageUploading && (
        <div className="nft-loader-container fullscreen topmost">
          <LoaderFood className="nft-loader" />
        </div>
      )}
    </>
  );
};

export default UploadImage;
