import { React, useState, useEffect } from "react";
import { Web3Auth } from "@web3auth/modal";
import { getPublicCompressed } from "@toruslabs/eccrypto";
import { ethers } from "ethers";
import { CHAIN_NAMESPACES, WALLET_ADAPTERS } from "@web3auth/base";
import axios from "axios";
import ContractService from "../../services/ContractService";
import { Button, Col, Row } from "reactstrap";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import RestaurantAdminService from "services/RestaurantAdminService";
import MainStorage from "store/MainStorage";
import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";
import LoaderSpoon from "components/Loaders/LoaderSpoon";

const Web3AuthComponent = () => {
  const [contractInstance, setContractInstance] = useState();

  const [web3auth, setWeb3auth] = useState();
  const [provider, setProvider] = useState(null);
  const [initializing, setInitializing] = useState(true);
  const [initFailed, setInitFailed] = useState(false);
  const [loginFailed, setLoginFailed] = useState(false);
  const [logoutFailed, setLogoutFailed] = useState(false);
  const [loggingIn, setLoggingIn] = useState(
    sessionStorage.getItem("loggingIn")
  );
  const [web3LoggedIn, setWeb3LoggedIn] = useState(false);

  const setContractInstance_easypeasy = useStoreActions(
    (actions) => actions.setContractInstance
  );
  const setProvider_ep = useStoreActions((actions) => actions.setProvider);
  const balance = useStoreState((state) => state.balance);
  const setBalance = useStoreActions((actions) => actions.setBalance);
  const account = useStoreState((state) => state.account);
  const setAccount = useStoreActions((actions) => actions.setAccount);
  const isLoggedIn = useStoreState((state) => state.isLoggedIn);
  const setLoggedIn = useStoreActions((actions) => actions.setLoggedIn);
  const setRestaurantId = useStoreActions((actions) => actions.setRestaurantId);
  const setHeaders = useStoreActions((actions) => actions.setHeaders);
  const setRole = useStoreActions((actions) => actions.setRole);
  const logout = useStoreState((state) => state.logout);
  const setLogout = useStoreActions((action) => action.setLogout);

  const history = useHistory();
  const location = useLocation();

  const chainConfig = {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: "0x13882",
    rpcTarget: process.env.REACT_APP_API_RPC_URL,
  };

  useEffect(() => {
    // console.log(location.pathname);
    setLoggingIn(sessionStorage.getItem("loggingIn"));
    const init = async () => {
      try {
        setInitFailed(false);
        const _web3auth = new Web3Auth({
          clientId: process.env.REACT_APP_WEB3AUTH_CLIENT_ID,
          web3AuthNetwork: "testnet", // mainnet, aqua, celeste, cyan or testnet
          chainConfig,
          uiConfig: {
            theme: "dark",
            loginMethodsOrder: ["google", "facebook"],
            appLogo: "/favicon/64_64.png",
          },
          sessionTime: 86400 * 7,
        });

        const privateKeyProvider = new EthereumPrivateKeyProvider({
          config: { chainConfig },
        });

        const openloginAdapter = new OpenloginAdapter({
          adapterSettings: {
            uxMode: "redirect",
            replaceUrlOnRedirect: false,
          },
          loginConfig: {
            sms_passwordless: {
              showOnModal: false,
              showOnDesktop: false,
              showOnMobile: false,
            },
          },
          privateKeyProvider,
        });

        _web3auth.configureAdapter(openloginAdapter);

        setWeb3auth(_web3auth);
        console.log("web3auth set: ", _web3auth);

        await _web3auth.initModal({
          modalConfig: {
            [WALLET_ADAPTERS.TORUS_EVM]: {
              showOnModal: false,
            },
            [WALLET_ADAPTERS.OPENLOGIN]: {
              label: "openlogin",
              loginMethods: {
                external_wallet: {
                  showOnModal: false,
                },
                sms_passwordless: {
                  name: "sms_passwordless",
                  showOnModal: false,
                },
                google: {
                  name: "google",
                  showOnModal: false,
                },
                facebook: {
                  name: "facebook",
                  showOnModal: false,
                },
                reddit: {
                  name: "reddit",
                  showOnModal: false,
                },
                github: {
                  name: "github",
                  showOnModal: false,
                },
                twitch: {
                  name: "twitch",
                  showOnModal: false,
                },
                discord: {
                  name: "discord",
                  showOnModal: false,
                },
                linkedin: {
                  name: "linkedin",
                  showOnModal: false,
                },
                apple: {
                  name: "apple",
                  showOnModal: false,
                },
                wechat: {
                  name: "wechat",
                  showOnModal: false,
                },
                line: {
                  name: "line",
                  showOnModal: false,
                },
                twitter: {
                  name: "twitter",
                  showOnModal: false,
                },
                kakao: {
                  name: "kakao",
                  showOnModal: false,
                },
                weibo: {
                  name: "weibo",
                  showOnModal: false,
                },
              },
            },
            [WALLET_ADAPTERS.WALLET_CONNECT_V2]: {
              label: "wallet_connect",
              showOnModal: false,
            },
            // Disable Metamask
            [WALLET_ADAPTERS.METAMASK]: {
              label: "metamask",
              showOnModal: false,
            },
          },
        });

        console.log("status:", _web3auth.status);
        const web3LoggedIn = _web3auth.status === "connected";
        setWeb3LoggedIn(web3LoggedIn);

        if (!web3LoggedIn) {
          handleWeb3AuthLogout(_web3auth);
          setInitializing(false);
        }

        if (web3LoggedIn) {
          handleLogin(_web3auth);
        }
      } catch (error) {
        console.log("INITIALIZATION_ERROR:", error);
        setInitFailed(true);
        MainStorage.persist.clear().then(() => {
          console.log("Persisted state has been removed");
        });
      }
    };

    init();
  }, []);

  useEffect(() => {
    if (provider) {
      getBalance();
      getWalletAddress();
    }
  }, [provider]);

  useEffect(() => {
    if (logout) {
      console.log("from logout useEffect");
      handleWeb3AuthLogout();
      setLogout(false);
    }
  }, [logout]);

  const handleLogin = async (_web3auth = web3auth) => {
    try {
      setLoggingIn(true);

      await getUserInfo(_web3auth);

      const adapterName = _web3auth?.connectedAdapterName;

      console.log("get user info completed");

      const cachedIdTokenTimestamp = localStorage.getItem("idTokenTimestamp");
      const cachedIdToken = localStorage.getItem("idToken");
      const currentTimestamp = new Date().getTime();
      let idToken = cachedIdToken;

      if (
        !cachedIdToken ||
        !cachedIdTokenTimestamp ||
        currentTimestamp - cachedIdTokenTimestamp > 86400000 // older than 24 hours
      ) {
        idToken = await getIdTokenJWT(_web3auth);
      }

      if (!localStorage.getItem("appPubKey")) {
        console.log("setting appPubKey");
        const idTokenData = await getIdTokenInfoForVerification(
          adapterName,
          _web3auth
        );
        localStorage.setItem("appPubKey", idTokenData.app_pub_key);
      }

      console.log("idtoken:", idToken);

      axios.defaults.headers.common["Authorization"] = `Bearer ${idToken}`;

      axios.defaults.headers.common["appPubKey"] =
        localStorage.getItem("appPubKey");

      setHeaders(true);

      try {
        const res = await RestaurantAdminService.authenticateAdmin();
        console.log(res);
        setRole(res.user?.role);
        setRestaurantId(res.restoData?._id);
        setLoggedIn(true);

        if (!contractInstance) {
          console.log("user is logged in, but contract instance is not set");
          const _contractInstance = await ContractService._intializeContract(
            _web3auth.provider
          );

          setContractInstance_easypeasy(_contractInstance);
          setContractInstance(_contractInstance);
          console.log("Contract instance set");
        }
      } catch (e) {
        console.log(e);
      }

      setInitializing(false);
      setLoggingIn(false);
      sessionStorage.removeItem("loggingIn");
    } catch (e) {
      console.log("LOGIN_ERROR:", e);
      setLoginFailed(true);
      setInitializing(false);
      alert("Something went wrong. Please try again.");
      handleWeb3AuthLogout(_web3auth);
      setLoggingIn(false);
      sessionStorage.removeItem("loggingIn");
    }
  };

  const handleWeb3AuthLogin = async () => {
    if (initFailed || !web3auth) {
      alert("Something went wrong. Please reload.");
      return;
    }

    // --- Extra security step (This step is added just in case logout function fails to logout web3auth, but other states [isLoggedIn etc] get cleared and then user tries to login again, the button will be usable.) ---
    const status = await web3auth.status;
    if (status === "connected") {
      if (loginFailed || logoutFailed) {
        console.log("Logging out web3auth");
        await web3auth.logout();
        setLoginFailed(false);
        setLogoutFailed(false);
      }
    }

    // --- Everything good, continue with login ---
    sessionStorage.setItem("loggingIn", true);
    await web3auth.connect();
  };

  const handleWeb3AuthLogout = async (_web3auth = web3auth) => {
    console.log(web3auth);

    if (!_web3auth || initFailed) {
      console.log("web3auth not initialized yet");
      alert("Something went wrong. Please reload.");
      // return;
    }

    if (_web3auth?.status === "connected") {
      try {
        await _web3auth.logout();
        setLogoutFailed(false);
        console.log("Logged out successfully");
      } catch (e) {
        console.log("Error in logging out", e);
        setLogoutFailed(true);
        MainStorage.persist.clear().then(() => {
          console.log("Persisted state has been removed");
        });
      }
    } else {
      console.log("User not logged into web3auth");
    }

    console.log("Resetting other states & localstorage");
    setLoggedIn(false);
    setLoginFailed(false);
    setBalance(null);
    setAccount(null);
    setContractInstance(null);
    MainStorage.persist.clear().then(() => {
      console.log("Persisted state has been removed");
    });
    setRole(null);
    setRestaurantId(null);
    setWeb3LoggedIn(false);

    setLoggingIn(false);
    sessionStorage.removeItem("loggingIn");

    delete axios.defaults.headers.common["Authorization"];
    delete axios.defaults.headers.common["appPubKey"];

    localStorage.removeItem("idToken");
    localStorage.removeItem("appPubKey");
    localStorage.removeItem("idTokenTimestamp");
    console.log("States & localStorage reset successful");
    if (location.pathname !== "/login") history.push("/");
  };

  const getBalance = async () => {
    const userAddress = await provider.listAccounts();
    const balance = ethers.utils.formatEther(
      await provider.getBalance(userAddress[0]) // Balance is in wei
    );

    console.log("balance:", balance);
    setBalance(balance);
    return balance;
  };

  const getWalletAddress = async () => {
    const acc = await provider.listAccounts();
    console.log("wallet address: ", acc);
    setAccount(acc[0]);

    // const alreadyLoggedInAccount = localStorage.getItem("walletAddress");

    // if (acc[0] !== alreadyLoggedInAccount) {
    //   handleWeb3AuthLogout();
    //   alert("Something went wrong. Please reload.");
    // }

    // localStorage.setItem("walletAddress", acc[0]);
  };

  const getUserInfo = async (_web3auth = web3auth) => {
    // web3auth parameter will be provided from init function when it is just initialized
    // from loginHandler function, no need to provide, as web3auth state variable will be already set till now
    console.log(_web3auth);
    const userInfo = await _web3auth.getUserInfo();
    console.log("userInfo:", userInfo);

    if (_web3auth.provider) {
      let _provider = new ethers.providers.Web3Provider(_web3auth.provider);
      // setting provider for calling getBalance() & getWalletAddress() through useEffect
      setProvider(_provider);
      setProvider_ep(_provider);
    }

    return userInfo;
  };

  const getIdTokenJWT = async (web3auth) => {
    console.log("setting idToken...");
    let idToken;

    if (!web3auth) {
      console.log("web3auth not initiazled, loggin out");
      handleWeb3AuthLogout();
    }

    // if (adapterName === "openlogin") {
    //   const userInfo = await getUserInfo(_web3auth);
    //   idToken = userInfo.idToken;
    //   console.log("userInfo", userInfo);
    // } else {
    //   const token = await _web3auth.authenticateUser();
    //   idToken = token.idToken;
    // }

    const token = await web3auth.authenticateUser();
    idToken = token.idToken;
    console.log("token:", token);
    localStorage.setItem("idToken", idToken);
    localStorage.setItem("idTokenTimestamp", new Date().getTime());
    return idToken;
  };

  const getIdTokenInfoForVerification = async (adapterName, web3auth) => {
    try {
      if (adapterName === "openlogin") {
        const app_scoped_privkey = await web3auth.provider?.request({
          method: "eth_private_key", // use "private_key" for other non-evm chains
        });
        const app_pub_key = getPublicCompressed(
          Buffer.from(app_scoped_privkey.padStart(64, "0"), "hex")
        ).toString("hex");

        /* Assuming user is logged in, get the userInfo containtaing idToken */
        const user = await web3auth.getUserInfo();

        return { user, app_pub_key };
      } else {
        // const provider = new ethers.providers.Web3Provider(window.ethereum);
        const provider = new ethers.providers.Web3Provider(web3auth.provider);
        // setProvider(provider);
        const walletAddress = (await provider.listAccounts())[0];
        const user = await web3auth.getUserInfo();
        return { user, app_pub_key: walletAddress };
      }
    } catch (error) {
      console.log("ERROR::getIdTokenInfoForVerification", error);
    }
  };

  if (!isLoggedIn)
    return (
      <>
        {!web3LoggedIn || loggingIn ? (
          <div className="web3auth-component">
            <Button
              type="submit"
              onClick={handleWeb3AuthLogin}
              size="lg"
              disabled={loggingIn === "true" || loggingIn}
              className={loggingIn ? "mb-3 w-100 py-0" : "mb-3 w-100"}
            >
              {loggingIn ? (
                <LoaderSpoon width={100} />
              ) : (
                <>
                  <div className="mb-2">
                    {" "}
                    <big> Login</big>{" "}
                  </div>
                  <small>using web3auth</small>{" "}
                </>
              )}
            </Button>
          </div>
        ) : (
          <div className="not-authorized-container">
            <h4 className="mb-0"> Your Acccount: </h4>
            <h4 className="account"> {account}</h4>
            <h4 className="font-weight-bold text-danger">
              Not authorized, please login with admin account
            </h4>
            <Button onClick={() => handleWeb3AuthLogout()} className=" w-100">
              Logout
            </Button>
          </div>
        )}
      </>
    );
  else
    return (
      <Row
        className="web3auth-component"
        xs={isLoggedIn && 2}
        sm={isLoggedIn && 3}
      >
        <Col>
          <Button
            title={account}
            disabled={!account}
            onClick={() => {
              try {
                navigator.clipboard.writeText(account);
              } catch (e) {
                console.log("Error while copying");
              }
            }}
          >
            {account
              ? account.substring(0, 4) +
                "..." +
                account.substring(account.length - 4)
              : "fetching Wallet addr"}
          </Button>
        </Col>

        <Col>
          <Button title="Balance" disabled={!balance} className="mx-1">
            {balance
              ? String(balance).substring(0, 5) + " Matic"
              : "fetching balance"}
          </Button>
        </Col>
        <Col className="logout-btn-col">
          <Button
            variant="danger"
            onClick={() => handleWeb3AuthLogout()}
            className="logout-button btn-danger"
          >
            Logout
          </Button>
        </Col>
      </Row>
    );
};

export default Web3AuthComponent;
