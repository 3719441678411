import axios from "axios";
import { useStoreState } from "easy-peasy";
import { ethers } from "ethers";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Button, Col, Row, Table } from "reactstrap";
import { markNFTAsMinted } from "services/BlockchainBackendService";
import { getCurrentMaticUSDPrice } from "services/MaticPriceService";
import "react-loading-skeleton/dist/skeleton.css";

const GiftConfirmGift = ({
  newGiftReceiver,
  setModalNumber,
  closeModal,
  setModalLoader,
  setLoadingMessage,
  newGift,
  notify,
  fetchGifts,
}) => {
  // const [loading, setLoading] = useState(false)
  const [gasFee, setGasFee] = useState(null);
  const [maticPrie, setMaticPrice] = useState("");
  const [gasError, setGasError] = useState(false);
  const contractInstance = useStoreState((state) => state.contractInstance);
  const provider = useStoreState((state) => state.provider);

  useEffect(() => {
    estimateGasFee();
    fetchMaticPrice();
  }, []);

  const fetchMaticPrice = async () => {
    const res = await getCurrentMaticUSDPrice(1);
    console.log("price:", res);
    setMaticPrice(res);
  };

  const handleSubmit = async () => {
    console.log(contractInstance);

    // const res = await axios.get(
    //   "https://web3-dev-backend.paidrez.com/reservation/654d1641ca7246a501279ec122"
    // );

    // console.log(res);
    setModalLoader(true);
    setLoadingMessage("Minting your Reservation");

    console.log("contract:", contractInstance);
    console.log("newGift", newGift);
    try {
      if (!newGift.isMinted) {
        const transaction = await contractInstance.mint(newGift.voucher, {
          // gasLimit: 600000,
          maxPriorityFeePerGas: "5000000000", // Max priority fee per gas
          maxFeePerGas: "60000000000",
          value: newGift.price,
        });

        const res = await transaction.wait();
        console.log("MINTED:", res);

        await markNFTAsMinted(newGift._id);
        console.log("Marked as minted");
      }

      setLoadingMessage("Transferring the Reservation to receiver");

      const from = (await provider.listAccounts())[0];
      const tokenId = newGift.tokenId;
      const toAddress = newGiftReceiver.walletAddress;

      console.log("From:", from);
      console.log("To: ", toAddress);

      const transaction_transfer = await contractInstance.transferFrom(
        from,
        toAddress,
        tokenId
      );

      const res_transfer = await transaction_transfer.wait();
      console.log("Transferred", res_transfer);
      fetchGifts();
      setModalLoader(false);
      setModalNumber(4);
    } catch (e) {
      console.log(e);
      setModalLoader(false);
      notify("Something Went Wrong");
    }
  };

  const estimateGasFee = async () => {
    const methodName = "mint";
    const methodArgs = [newGift.voucher];

    const gas_fee_url = process.env.REACT_APP_GAS_FEES_URL;

    try {
      // throw new Error("e");
      let gasPriceInfo = await fetch(gas_fee_url)
        .then((response) => response.json())
        .catch((e) => {
          console.log("Error fetching gas price:", e);
          // handleModalError();
        });

      console.log("gas price info:", gasPriceInfo);

      const options = {
        // maxPriorityFeePerGas: "5000000000", // Max priority fee per gas
        // maxFeePerGas: "60000000000",
        gasPrice: ethers.utils.parseUnits(
          gasPriceInfo.fast.maxFee.toFixed(6),
          "gwei"
        ), // Set your desired gas price
        value: newGift.price,
      };

      let gasEstimate;
      console.log("contractInstance", contractInstance);
      if (!newGift.isMinted) {
        gasEstimate = await contractInstance.estimateGas[methodName](
          newGift.voucher,
          options
        );
      } else {
        console.log("estimating for transfer only");
        const from = (await provider.listAccounts())[0];
        const tokenId = newGift.tokenId;
        const toAddress = newGiftReceiver.walletAddress;

        gasEstimate = await contractInstance.estimateGas.transferFrom(
          from,
          toAddress,
          tokenId
        );
      }

      console.log(gasEstimate);

      let gasFee = ethers.utils
        .formatUnits(gasEstimate.mul(options.gasPrice), "ether")
        .substring(0, 10);

      gasFee = Number(gasFee).toFixed(5);

      setGasFee(gasFee);

      // const _priceInUSD = await getCurrentMaticUSDPrice(gasFee);
      // setPriceInUSD(_priceInUSD);

      console.log("Gas estimate:", gasEstimate.toString());
      console.log("Estimated gas fees:", gasFee);
    } catch (e) {
      console.log("ERROR::", e);
      setGasError(true);
    }
  };

  return (
    <div>
      <div className="reservation-details">
        <div className="img-container">
          <img
            src="https://paidrez.s3.us-east-2.amazonaws.com/PaidRez-0xc76814f67e3e8e66722f4dcb142fb26dfdc9160a4be0c4d6149236d38f458eab"
            alt=""
            width={100}
            height={100}
          />
        </div>

        <div className="details-container">
          <div className="d-flex">
            <div className="detail">Token ID</div>
            <div className="value">#{newGift?.tokenId}</div>
          </div>
          <div className="d-flex">
            <div className="detail">Date</div>
            <div className="value">
              {moment(new Date(newGift?.slotTime * 1000)).format(
                "MMM Do YYYY, dddd"
              )}
            </div>
          </div>
          <div className="d-flex">
            <div className="detail">Time</div>
            <div className="value">
              {moment(new Date(newGift?.slotTime * 1000)).format("LT")}
            </div>
          </div>

          <div className="d-flex">
            <div className="detail">Guests</div>
            <div className="value">{newGift?.seatCapacity}</div>
          </div>
        </div>
      </div>

      <p>Sending to </p>
      <div className="wallet-address-container">
        <b className="paidrez-color mr-1">{newGiftReceiver?.walletAddress}</b>
      </div>
      {/* <Button onClick={() => setModalNumber(2)} size="sm">
        Change
      </Button> */}

      <hr />

      <div className="mb-5">
        <div className="d-flex justify-content-between">
          <p>Estimated Gas Fee</p>
          <div>
            <big>
              <b className="paidrez-color">
                {!gasFee && !gasError ? (
                  <Skeleton height={25} width={150} />
                ) : (
                  <>{newGift.isMinted ? gasFee : gasFee * 2} MATIC </>
                )}
              </b>
            </big>

            {/* <br /> */}
            <p className="text-right">
              <b className="paidrez-color">
                {!gasFee && !gasError ? (
                  <Skeleton height={20} width={125} />
                ) : (
                  (newGift.isMinted
                    ? gasFee * maticPrie
                    : gasFee * maticPrie * 2
                  ).toFixed(6) + " USD"
                )}
              </b>
            </p>
          </div>
        </div>
        {gasError && (
          <b>
            Couldn't estimate gas required for this transaction. You can still
            continue with the transaction.
          </b>
        )}
      </div>

      <Row className="text-center">
        <Button
          className="paidrez-btn col"
          onClick={() => {
            handleSubmit();
            // setModalNumber(4);
          }}
          disabled={!newGift}
        >
          Confirm
        </Button>
        <Button className="paidrez-btn-secondary col" onClick={closeModal}>
          Reject
        </Button>
      </Row>
    </div>
  );
};

export default GiftConfirmGift;
