import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";
import GiftAddNew from "./popups/GiftAddNew";
import GiftConfirmMint from "./popups/GiftConfirmMint";
import "../assets/scss/gift-page.scss";
import GiftChooseReceiver from "./popups/GiftChooseReceiver";
import GiftConfirmGift from "./popups/GiftConfirmGift";
import GiftSuccess from "./popups/GiftSuccess";
import { useStoreState } from "easy-peasy";
import RestaurantService from "services/RestaurantService";
import ReactNotificationAlert from "react-notification-alert";
import LoaderSpoon from "components/Loaders/LoaderSpoon";
import LoaderFood from "components/Loaders/LoaderFood";
import SlotsService from "services/SlotsService";
import moment from "moment";

const Gift = () => {
  const [restaurant, setRestaurant] = useState(null);
  const [modal, setModal] = useState(false);
  const [modalNumber, setModalNumber] = useState(-1);
  const [gifts, setGifts] = useState([]);
  const [newGiftReceiver, setNewGiftReceiver] = useState(null);
  const [newGift, setNewGift] = useState(null);
  const [modalLoader, setModalLoader] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [copiedKey, setCopiedKey] = useState(-1);
  const [past, setPast] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(8);
  const restaurantId = useStoreState((states) => states.restaurantId);
  const role = useStoreState((state) => state.role);
  const headers = useStoreState((states) => states.headers);
  const account = useStoreState((states) => states.account);

  const notificationAlertRef = useRef(null);

  useEffect(() => {
    fetchRestaurantDetails();
  }, []);

  useEffect(() => {
    if (role === "RESTO_ADMIN" && headers) {
      fetchGifts();
    }
  }, [headers, role, past, pageNo]);

  useEffect(() => {
    if (modalNumber === -1) {
      setModal(false);
    } else {
      setModal(true);
    }
  }, [modalNumber]);

  const notify = (message = <span>Something went wrong</span>) => {
    var options = {
      place: "bc",
      message: message,
      type: "danger",
      icon: "tim-icons  icon-alert-circle-exc",
      autoDismiss: 5,
    };

    notificationAlertRef.current?.notificationAlert(options);
  };

  const closeModal = () => {
    setNewGiftReceiver(null);
    setModalNumber(-1);
    setShowConfirmation(false);
  };

  const fetchGifts = async () => {
    setLoading(true);
    try {
      let params;

      if (past) {
        params = { pageNo, pageSize, endTime: new moment().unix() };
      } else {
        params = {
          pageNo,
          pageSize,
          startTime: new moment().unix(),
          sortOrder: "ASC",
        };
      }

      const res = await SlotsService.getGifts(params);
      console.log(res);
      setGifts(res.data?.slots);
      setTotalPages(res.data?.totalPages);
      setLoading(false);
    } catch (e) {
      notify("Couldn't fetch gifts");
      setLoading(false);
    }
  };

  const fetchRestaurantDetails = () => {
    console.log("fetch restaurant details");
    RestaurantService.getRestaurantById(restaurantId)
      .then((res) => {
        console.log("restaurant:", res.data);
        setRestaurant(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleSendGiftNow = (gift) => {
    setNewGift(gift);
    setShowConfirmation(true);
    setModalNumber(2);
  };

  return (
    <div className="content gift-page">
      <Button onClick={() => setModalNumber(0)} className="paidrez-btn">
        New Gift
      </Button>
      <br />
      <Row className="mx-0 top-buttons">
        <Button
          onClick={() => setPast(false)}
          className={
            past
              ? "paidrez-btn-secondary col mx-0 left"
              : "paidrez-btn col mx-0 left"
          }
        >
          Upcoming Gifts
        </Button>
        <Button
          onClick={() => setPast(true)}
          className={
            !past
              ? "paidrez-btn-secondary col mx-0 right"
              : "paidrez-btn col mx-0 right"
          }
        >
          Past Gifts
        </Button>
      </Row>

      {loading && gifts.length === 0 ? (
        <LoaderSpoon width={150} className="d-block mx-auto" />
      ) : (
        <div className="position-relative">
          {loading && gifts.length > 0 && (
            <div className="w-100 h-100 position-absolute bg-light d-flex justify-content-center align-items-center">
              <LoaderSpoon width={150} />
            </div>
          )}
          <Table className="tablesorter" responsive>
            <thead className="text-primary whitespace-nowrap">
              <tr>
                <th>Date</th>
                <th>Time</th>
                <th>Token Id</th>
                <th className="text-center">Seats</th>
                <th>Wallet Address</th>
                <th>Name</th>
                <th>Email</th>
                {/* <th>Contact</th> */}
              </tr>
            </thead>
            <tbody className="whitespace-nowrap">
              {gifts.map((slot, i) => (
                <tr key={i}>
                  <td>
                    {slot.slotTime &&
                      new Date(slot.slotTime * 1000).toLocaleDateString()}
                  </td>
                  <td>
                    {slot.slotTime &&
                      new Date(slot.slotTime * 1000)
                        .toTimeString()
                        .substring(0, 5)}
                  </td>
                  <td>{slot.tokenId}</td>
                  <td className="text-center">{slot.seatCapacity}</td>
                  <td>
                    {slot.ownerData?.walletAddress && (
                      <>
                        <>
                          {slot.ownerData.walletAddress.substring(0, 4) +
                            " . . . " +
                            slot.ownerData.walletAddress.substring(
                              slot.ownerData.walletAddress.length - 4
                            )}
                        </>

                        <div className="copy-button ms-1">
                          {copiedKey === i ? (
                            <svg viewBox="0 0 24 24" className="copy-success">
                              <path
                                fill="currentColor"
                                d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"
                              ></path>
                            </svg>
                          ) : (
                            <svg
                              viewBox="0 0 24 24"
                              className="copy"
                              onClick={() => {
                                setCopiedKey(i);
                                setTimeout(() => setCopiedKey(-1), 3000);
                              }}
                            >
                              <path
                                fill="currentColor"
                                d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z"
                              ></path>
                            </svg>
                          )}
                        </div>
                      </>
                    )}
                  </td>

                  <td>{slot.ownerData?.name}</td>

                  <td>{slot.ownerData?.email}</td>
                  {/* <td>
                {
                  <div title={slot.walletAddress}>
                    {
                      slot.walletAddress
                      // .substring(0, 4) +
                      //   "..." +
                      //   slot.walletAddress.substring(
                      //     slot.walletAddress.length - 4
                      //   )
                    }
                  </div>
                }
              </td> */}

                  <td>
                    <Button
                      disabled={
                        slot.isMinted &&
                        slot.ownerData?.walletAddress?.toLowerCase() !==
                          account.toLowerCase()
                      }
                      className=" px-3 paidrez-btn btn-sm"
                      onClick={() => handleSendGiftNow(slot)}
                    >
                      Gift Now
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}

      {totalPages !== 0 && (
        <div className="pagination-container ">
          <Pagination size="sm">
            <PaginationItem disabled={pageNo === 1}>
              <PaginationLink
                previous
                onClick={() =>
                  setPageNo((pageNo) => (pageNo === 1 ? pageNo : pageNo - 1))
                }
              />
            </PaginationItem>

            {Array.from(Array(totalPages).keys()).map((_, i) => (
              <PaginationItem active={pageNo === i + 1}>
                <PaginationLink onClick={() => setPageNo(i + 1)}>
                  {i + 1}
                </PaginationLink>
              </PaginationItem>
            ))}

            <PaginationItem disabled={pageNo === totalPages}>
              <PaginationLink
                next
                onClick={() => setPageNo((pageNo) => pageNo + 1)}
              />
            </PaginationItem>
          </Pagination>
        </div>
      )}

      <Modal
        isOpen={modal}
        toggle={closeModal}
        backdrop={true}
        centered={true}
        fade={true}
        fullscreen={true}
        className={
          "gift-modal " +
          {
            0: "add-new",
            1: "confirm-mint",
            2: "choose-receiver",
            3: "confirm-gift",
            4: "gift-sent",
          }[modalNumber]
        }
      >
        <ModalHeader toggle={closeModal} className="text-center">
          <h4 className="mb-0">
            {
              {
                0: "Add New Gift",
                1: "Confirm Mint",
                2: "Choose Receiver",
                3: "Confirm Gift",
                4: "Success",
              }[modalNumber]
            }
          </h4>
        </ModalHeader>
        <ModalBody>
          {
            {
              0: (
                <GiftAddNew
                  setModalNumber={setModalNumber}
                  closeModal={closeModal}
                  newGiftReceiver={newGiftReceiver}
                  restoId={restaurant?.restoId}
                  notify={notify}
                  setNewGiftReceiver={setNewGiftReceiver}
                  setModalLoader={setModalLoader}
                  setLoadingMessage={setLoadingMessage}
                  setNewGift={setNewGift}
                />
              ),
              // Below component is not in use
              1: (
                <GiftConfirmMint
                  setModalNumber={setModalNumber}
                  closeModal={closeModal}
                  notify={notify}
                />
              ),
              // *******************************

              2: (
                <GiftChooseReceiver
                  setModalNumber={setModalNumber}
                  closeModal={closeModal}
                  newGiftReceiver={newGiftReceiver}
                  setNewGiftReceiver={setNewGiftReceiver}
                  notify={notify}
                  showConfirmation={showConfirmation}
                />
              ),
              3: (
                <GiftConfirmGift
                  setModalNumber={setModalNumber}
                  closeModal={closeModal}
                  newGiftReceiver={newGiftReceiver}
                  notify={notify}
                  setModalLoader={setModalLoader}
                  setLoadingMessage={setLoadingMessage}
                  newGift={newGift}
                  fetchGifts={fetchGifts}
                />
              ),
              4: (
                <GiftSuccess
                  closeModal={closeModal}
                  newGift={newGift}
                  newGiftReceiver={newGiftReceiver}
                  setModalNumber={setModalNumber}
                />
              ),
            }[modalNumber]
          }
        </ModalBody>
      </Modal>

      {modalLoader && (
        <div className="nft-loader-container fullscreen topmost">
          <LoaderFood className="loader" />
          <h4 className="text-center">{loadingMessage}</h4>
        </div>
      )}

      <div className="react-notification-alert-container">
        <ReactNotificationAlert ref={notificationAlertRef} />
      </div>
    </div>
  );
};

export default Gift;
