/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import LoaderSpoon from "components/Loaders/LoaderSpoon";
import { useStoreState } from "easy-peasy";
import moment from "moment";
import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import SlotsService from "services/SlotsService";
import QrCodeScanner from "./QrCodeScanner";

function Tables() {
  const restaurantId = useStoreState((states) => states.restaurantId);
  const headers = useStoreState((states) => states.headers);
  const [pageNo, setPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [slots, setSlots] = useState([]);
  const [loading, setLoading] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [stopScanner, setStopScanner] = useState(false);

  const toggle = () => {
    console.log("stopScanner", stopScanner);
    if (modal) {
      console.log("modal:", modal);
      setStopScanner(true);
      console.log("scanner stopped");
    }
    setModal(!modal);
  };

  const fetchUpcomingReservations = () => {
    setLoading(true);
    const startTime = moment(new Date()).unix();
    console.log(startTime);
    SlotsService.getUpcomingMintedSlots({
      restoId: restaurantId,
      startTime,
      pageNo,
    })
      .then((res) => {
        setSlots(res.data.slots);
        setTotalPages(res.data.totalPages);
        setClicked(false);
        setLoading(false);
      })
      .catch((e) => {
        console.error("Error fetching slots:", e);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (restaurantId && headers) fetchUpcomingReservations();
  }, [restaurantId, headers, pageNo]);

  const handleMarkAsUtilized = (tokenId) => {
    setClicked(true);
    SlotsService.markAsUtilized(tokenId)
      .then(() => {
        fetchUpcomingReservations();
      })
      .catch((e) => {
        console.log(e);
        setClicked(false);
      });
  };

  return (
    <>
      <div className="content upcoming-reservations">
        <Row>
          <Col md="12">
            <Card className="position-relative">
              <CardHeader>
                <CardTitle tag="h3" className="text-center text-bold">
                  UPCOMING RESERVATIONS
                </CardTitle>
                <Button
                  className="mx-auto d-block"
                  onClick={() => setModal(true)}
                >
                  Verify Guest
                </Button>
              </CardHeader>
              <CardBody>
                <Table className="tablesorter" responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>Date</th>
                      <th>Time</th>
                      <th>Token ID</th>
                      <th>NFT</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Wallet Address</th>

                      {/* <th>Contact</th> */}
                      <th className="text-center">Seats</th>
                    </tr>
                  </thead>
                  <tbody>
                    {slots
                      .filter((slot) => slot.isMinted === true)
                      .map((slot, i) => (
                        <tr key={i}>
                          <td>
                            {slot.slotTime &&
                              new Date(
                                slot.slotTime * 1000
                              ).toLocaleDateString()}
                          </td>
                          <td>
                            {slot.slotTime &&
                              new Date(slot.slotTime * 1000)
                                .toTimeString()
                                .substring(0, 5)}
                          </td>
                          <td>{slot.tokenId}</td>
                          <td>
                            {slot.tokenMetadata
                              ? slot.tokenMetadata.name
                              : "N/A"}
                          </td>

                          <td>{slot.userData?.name}</td>

                          <td>{slot.userData?.email}</td>
                          <td>
                            {
                              <div title={slot.walletAddress}>
                                {slot.walletAddress.substring(0, 4) +
                                  "..." +
                                  slot.walletAddress.substring(
                                    slot.walletAddress.length - 4
                                  )}
                              </div>
                            }
                          </td>
                          <td>{slot.tokenId}</td>
                          <td className="text-center">{slot.seatCapacity}</td>
                          <td>
                            <Button
                              disabled={
                                !slot.isUtilized ||
                                slot.markedAsUtilized ||
                                clicked
                              }
                              onClick={() => handleMarkAsUtilized(slot.tokenId)}
                              title={
                                !slot.isUtilized ? "Not used by user yet" : ""
                              }
                              className="w-100 use-button"
                            >
                              {slot.markedAsUtilized ? "Used" : "Mark as Used"}
                            </Button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>

                {totalPages !== 0 && (
                  <div className="pagination-container ">
                    <Pagination className="justify-content-center mb-0">
                      <PaginationItem disabled={pageNo === 1}>
                        <PaginationLink
                          previous
                          onClick={() =>
                            setPageNo((pageNo) =>
                              pageNo === 1 ? pageNo : pageNo - 1
                            )
                          }
                        />
                      </PaginationItem>

                      {Array.from(Array(totalPages).keys()).map((_, i) => (
                        <PaginationItem active={pageNo === i + 1} key={i}>
                          <PaginationLink onClick={() => setPageNo(i + 1)}>
                            {i + 1}
                          </PaginationLink>
                        </PaginationItem>
                      ))}

                      <PaginationItem disabled={pageNo === totalPages}>
                        <PaginationLink
                          next
                          onClick={() => setPageNo((pageNo) => pageNo + 1)}
                        />
                      </PaginationItem>
                    </Pagination>
                  </div>
                )}
              </CardBody>
              {loading && (
                <div className="loader-container">
                  <LoaderSpoon width={150} className="loader" />
                </div>
              )}
            </Card>
          </Col>
        </Row>
      </div>

      <Modal
        isOpen={modal}
        toggle={toggle}
        backdrop={true}
        centered={true}
        fade={true}
        fullscreen={true}
        className="qrcode-modal"
      >
        <ModalHeader toggle={toggle} className="text-center">
          Verify Guest
        </ModalHeader>
        <ModalBody>
          <QrCodeScanner stopScanner={stopScanner} />
        </ModalBody>
        {/* <ModalFooter>
          <Button color="primary" onClick={toggle}>
            Do Something
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter> */}
      </Modal>
    </>
  );
}

export default Tables;
