import React from "react";
import { Button, Row } from "reactstrap";

const GiftConfirmMint = ({ setModalNumber, closeModal }) => {
  return (
    <div>
      <p>Estimated Gas Fee</p>
      <div className="d-flex justify-content-between mb-5">
        <b className="paidrez-color">0.002 MATIC</b>
        <b className="paidrez-color">0.001 USD</b>
      </div>

      {/* <Button onClick={() => setModalNumber(2)}>next</Button> */}

      <Row className="text-center">
        <Button className="paidrez-btn col" onClick={() => setModalNumber(2)}>
          Confirm
        </Button>
        <Button className="paidrez-btn-secondary col" onClick={closeModal}>
          Reject
        </Button>
      </Row>
    </div>
  );
};

export default GiftConfirmMint;
