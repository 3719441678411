import React from "react";
import nftLoader from "./../../assets/loader-animation/spoon-rotation-animation.gif";

const LoaderSpoon = ({ className, width }) => {
  return (
    <img
      src={nftLoader}
      alt="loader-animation"
      className={className}
      width={width}
    />
  );
};

export default LoaderSpoon;
