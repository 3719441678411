import axios from "axios";
import { ethers } from "ethers";

const BASE_URL = process.env.REACT_APP_BLOCKCHAIN_BE_SERVER_URL;
const customConfig = {
  headers: {
    "Content-Type": "application/json",
  },
};

export async function confirmSlot({ name, location, slotTime, pax, nftImage }) {
  try {
    const restoId = ethers.utils.solidityKeccak256(
      ["string", "string"],
      [name, location]
    );
    const timestamp = Math.floor(new Date(slotTime).getTime() / 1000);
    const body = JSON.stringify({
      restoId,
      name,
      location,
      slotTime: timestamp,
      seatCapacity: pax,
      nftImage: nftImage.base64_image,
      duration: 2,
    });
    const response = await axios.post(
      `${BASE_URL}/confirm-slot`,
      body,
      customConfig
    );
    return await response.data;
  } catch (error) {
    console.log("ERROR::confirmSlot", error);
  }
}

export async function markNFTAsMinted(id) {
  try {
    const body = JSON.stringify({ id });
    const response = await axios.post(
      `${BASE_URL}/mark-as-minted`,
      body,
      customConfig
    );
    return await response.data;
  } catch (error) {
    console.log("ERROR::markNFTAsMinted", error);
    throw new Error(error);
  }
}

export function getCustomers(restaurantId, params) {
  return axios.get(BASE_URL + "/customers/" + restaurantId, { params });
}
