import { useStoreState } from "easy-peasy";
import moment from "moment";
import React, { useState } from "react";
import QrReader from "react-qr-reader";
import { Button, Table } from "reactstrap";
import SlotsService from "services/SlotsService";
// import { QrReader } from "react-qr-reader";

// eslint-disable-next-line no-use-before-define
const style = { width: "500px" };

const QrCodeScanner = ({ stopScanner }) => {
  const [selected, setSelected] = useState("environment");
  const [startScan, setStartScan] = useState(false);
  const [loadingDataFromAPI, setLoadingDataFromAPI] = useState(false);
  const [scanData, setScanData] = useState("");
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [reservationData, setReservationData] = useState(null);
  const [used, setUsed] = useState(false);
  const restaurantId = useStoreState((states) => states.restaurantId);

  useState(() => {
    console.log("stopScanner:", stopScanner);
    if (stopScanner) {
      console.log("Stopped");
      setStartScan(false);
    }
  }, [stopScanner]);

  const handleError = (message = "Something went wrong") => {
    setLoadingDataFromAPI(false);
    setErrorMsg(message);
    setError(true);
  };

  const fetchReservationDetails = async (id) => {
    if (id.length !== 24) {
      console.log("invalid id");
      handleError("Invalid QR code");
      return;
    }

    setLoadingDataFromAPI(true);

    try {
      const res = await SlotsService.getSlotById(id);
      console.log(res.data);
      if (res.data?.restoData?._id !== restaurantId) {
        console.log("Restaurant mismatch");
        handleError("Uh Oh! Looks like reservation isn't for this restaurant");
        return;
      } else {
        setReservationData(res.data);
        setUsed(res.data?.reservation?.markedAsUtilized);
      }
      setLoadingDataFromAPI(false);
    } catch (e) {
      console.log("RESERVATION_FETCHING_ERROR:", e);
      setLoadingDataFromAPI(false);
    }
  };

  const handleScan = async (scanData) => {
    console.log(`loaded data data`, scanData);
    if (scanData && scanData !== "") {
      console.log(`loaded >>>`, scanData);
      setScanData(scanData);
      setStartScan(false);

      if (scanData[0] + scanData[1] !== "id") {
        console.log("id not detected");
        handleError("Invalid QR code");
        return;
      }

      fetchReservationDetails(scanData.substring(3));
      // setPrecScan(scanData);
    }
  };

  const restart = () => {
    console.log("restart clicked");
    setError(false);
    setStartScan(!startScan);
    setScanData(null);
    setReservationData(null);
    setUsed(false);
  };

  const handleMarkAsUtilized = (tokenId) => {
    // setClicked(true);
    SlotsService.markAsUtilized(tokenId)
      .then(() => {
        setUsed(true);
      })
      .catch((e) => {
        console.log(e);
        // setClicked(false);
      });
  };

  return (
    <div className="qrcode-scanner">
      {!scanData && !error && (
        <>
          <select
            onChange={(e) => {
              setError(false);
              setSelected(e.target.value);
            }}
          >
            <option value={"environment"}>Back Camera</option>
            <option value={"user"}>Front Camera</option>
          </select>

          <div className="scanner-container" key={startScan}>
            <QrReader
              facingMode={selected}
              delay={1000}
              onError={() => handleError("Couldn't start camera")}
              onScan={handleScan}
              // style={{ width: "400px" }}
              className="qr-reader"
            />
          </div>
        </>
      )}

      {error && (
        <div className="error-container">
          <h4>{errorMsg}</h4>
          <Button className="d-block m-auto" size="sm" onClick={restart}>
            Retry
          </Button>
        </div>
      )}

      {/* {loadingScan && "loading"} */}
      {loadingDataFromAPI && <p>Loading</p>}

      {/* {data !== "" && <p>{data}</p>} */}
      {reservationData && (
        <div className="reservation-details">
          <img
            src={reservationData.reservation.s3ImageURI}
            alt="nft"
            width={200}
            className="d-block m-auto"
          />
          <Table borderless>
            <tbody>
              <tr>
                <td className="detail">Token Id</td>
                <td className="value">{reservationData.reservation.tokenId}</td>
              </tr>
              <tr>
                <td className="detail">Name</td>
                <td className="value">{reservationData.ownerData?.name}</td>
              </tr>
              {reservationData.ownerData?.email && (
                <tr>
                  <td className="detail">Email</td>
                  <td className="value">{reservationData.ownerData?.email}</td>
                </tr>
              )}
              <tr>
                <td className="detail pr-3">Address</td>
                <td className="value">
                  {reservationData.ownerAddress.substring(0, 4) +
                    "..." +
                    reservationData.ownerAddress.substring(
                      reservationData.ownerAddress.length - 4
                    )}
                </td>
              </tr>
              <tr>
                <td className="detail">Date</td>
                <td className="value">
                  {moment(reservationData.reservation.slotTime * 1000).format(
                    "dddd, MMM Do YYYY"
                  )}
                </td>
              </tr>
              <tr>
                <td className="detail">Time</td>
                <td className="value">
                  {moment(reservationData.reservation.slotTime * 1000).format(
                    "LT"
                  )}
                </td>
              </tr>
              <tr>
                <td className="detail">Guests</td>
                <td className="value">
                  {reservationData.reservation.seatCapacity}{" "}
                </td>
              </tr>
            </tbody>
          </Table>

          <Button
            className="d-block mx-auto mb-2 text-white"
            onClick={() =>
              handleMarkAsUtilized(reservationData.reservation.tokenId)
            }
            disabled={used}
          >
            {used ? "Confirmed" : "Confirm"}
          </Button>
          <Button className="d-block mx-auto" size="sm" onClick={restart}>
            Scan Again
          </Button>
        </div>
      )}
    </div>
  );
};

export default QrCodeScanner;
