import axios from "axios";

const COINGECKO_URL = process.env.REACT_APP_COINGECKO_URL;

const fetchMaticPrice = async () => {
    try {
      const response = await axios.get(
        `${COINGECKO_URL}/simple/price?ids=matic-network&vs_currencies=usd`
      );
      const price = response['data']['matic-network']['usd'];

      // Cache the fetched price and timestamp in localStorage
      localStorage.setItem('maticPrice', JSON.stringify(price));
      localStorage.setItem('maticPriceTimestamp', new Date().getTime());
    } catch (error) {
      console.error('ERROR::fetchMaticPrice:', error);
    }
  };

export async function getCurrentMaticUSDPrice(maticAmount) {
  try {
    if(!maticAmount) return 0;
    const cachedPrice = localStorage.getItem('maticPrice');
    const cachedTimestamp = localStorage.getItem('maticPriceTimestamp');
    const currentTime = new Date().getTime();
    let maticUSDPrice = cachedPrice;
    // Check if cached data exists and is less than 5 minutes old
    if (cachedPrice && cachedTimestamp && currentTime - cachedTimestamp < 300000) {
        maticUSDPrice =  cachedPrice
    } else {
        await fetchMaticPrice();
        maticUSDPrice = localStorage.getItem('maticPrice')
    }
    return maticUSDPrice * maticAmount;
  } catch (error) {
    console.log("ERROR::getCurrentMaticUSDPrice", error);
  }
}
