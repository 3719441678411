import React from "react";
import { Button, Col, Row } from "reactstrap";

const WhitelistToggleConfirmation = ({
  closeModal,
  handleToggle,
  whitelistStatus,
  statusLoader,
}) => {
  return (
    <div>
      <h4>
        Are you sure to turn {whitelistStatus ? "off" : "on"} the white list
        status?
      </h4>
      {whitelistStatus ? (
        <h5>
          All the available reservations will become open to all. i.e. Anyone
          can mint them.
        </h5>
      ) : (
        <h5>
          All the available reservations will be marked as whitelist only.
        </h5>
      )}

      <Row>
        <Col>
          <Button
            className="w-100"
            onClick={() => handleToggle()}
            disabled={statusLoader}
          >
            Turn {whitelistStatus ? "Off" : "On"}
          </Button>
        </Col>
        <Col>
          <Button
            className="w-100"
            onClick={closeModal}
            disabled={statusLoader}
          >
            Cancel
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default WhitelistToggleConfirmation;
