import { ethers } from "ethers";
import PaidrezABI from "./../abi/paidrezABI.json";

class ContractService {
  contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
  provider = null;

  async _intializeContract(web3AuthProvider) {
    // We first initialize ethers by creating a provider using window.ethereum
    // When, we initialize the contract using that provider and the token's
    // artifact. You can do this same thing with your contracts.
    // console.log("init:", signer);
    this.provider = new ethers.providers.Web3Provider(web3AuthProvider);
    console.log(
      "wallet address",
      await this.provider.listAccounts(),
      this.provider.getSigner()
    );
    console.log("provider:", this.provider);
    const signer = this.provider.getSigner();
    console.log("signer:", signer);
    const contract = new ethers.Contract(
      this.contractAddress,
      PaidrezABI,
      signer
    );
    return contract;
  }
}

export default new ContractService();
