import React from "react";
import { Button, Col, Row } from "reactstrap";

const WhitelistDeleteConfirmation = ({
  closeModal,
  walletAddress,
  handleDelete,
  loader,
}) => {
  return (
    <div>
      <h4>Are you sure to delete this wallet address from whitelist?</h4>
      <h4>
        <b className="paidrez-color"> {walletAddress} </b>
      </h4>

      <Row>
        <Col>
          <Button
            className="btn-danger w-100"
            onClick={() => handleDelete(walletAddress)}
            disabled={loader}
          >
            Delete
          </Button>
        </Col>
        <Col>
          <Button className="w-100" onClick={closeModal} disabled={loader}>
            Cancel
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default WhitelistDeleteConfirmation;
