import LoaderSpoon from "components/Loaders/LoaderSpoon";
import { useStoreState } from "easy-peasy";
import React, { useEffect, useRef, useState } from "react";
import { Button, Form, FormGroup, Input, Label, Row, Table } from "reactstrap";
import { getCustomers } from "services/BlockchainBackendService";

const res = [
  {
    name: "Naman",
    walletAddress: "0xe0005eC8266263f0649E6dd1E7f650a23E3C7B01",
    email: "namanathem000@gmail.com",
    mobile: "1234567890",
    id: 1,
  },
  {
    name: "John",
    walletAddress: "0xe0005eC8266263f0649E6dd1E7f650a23E3C7B01",
    email: "john@gmail.com",
    mobile: "1234567890",
    id: 2,
  },
  {
    name: "Smith",
    walletAddress: "0xe0005eC8266263f0649E6dd1E7f650a23E3C7B01",
    email: "smith@gmail.com",
    mobile: "1234567890",
    id: 3,
  },
  {
    name: "Smith",
    walletAddress: "0xe0005eC8266263f0649E6dd1E7f650a23E3C7B01",
    email: "smith@gmail.com",
    mobile: "1234567890",
    id: 4,
  },
  {
    name: "Smith",
    walletAddress: "0xe0005eC8266263f0649E6dd1E7f650a23E3C7B01",
    email: "smith@gmail.com",
    mobile: "1234567890",
    id: 5,
  },
  {
    name: "Smith",
    walletAddress: "0xe0005eC8266263f0649E6dd1E7f650a23E3C7B01",
    email: "smith@gmail.com",
    mobile: "1234567890",
    id: 6,
  },
];

const GiftChooseReceiver = ({
  setModalNumber,
  closeModal,
  newGiftReceiver,
  setNewGiftReceiver,
  notify,
  showConfirmation,
}) => {
  const [searchParam, setSearchParam] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selected, setSelected] = useState(null);
  const [validWalletAddress, setValidWalletAddress] = useState(false);

  const restaurantId = useStoreState((states) => states.restaurantId);

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      fetchCustomers();
      if (isValidWalletAddress(searchParam)) {
        setValidWalletAddress(true);
      } else {
        setValidWalletAddress(false);
      }
    }, 500);

    return () => clearTimeout(delayDebounce);
  }, [searchParam]);

  const fetchCustomers = async () => {
    setLoading(true);
    try {
      const res = await getCustomers(restaurantId, {
        pageNo,
        pageSize,
        searchParam,
      });
      console.log(res);
      setSearchResults(res.data?.data?.customerList);
      setSelected(null);
      setLoading(false); // if(res.data?.data?.)
    } catch (e) {
      notify("Couldn't get customers list");
      console.log("ERROR::", e);
      setLoading(false);
    }
  };

  const isValidWalletAddress = (address) => {
    if (address.length === 42) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = () => {
    if (selected) {
      setNewGiftReceiver(selected);
    } else {
      setNewGiftReceiver({ walletAddress: searchParam });
    }

    if (showConfirmation) {
      setModalNumber(3);
    } else {
      closeModal();
    }
  };

  return (
    <div>
      <p>Search past customers (by name, email) or enter wallet address.</p>

      <Form>
        <FormGroup>
          <Input
            value={searchParam}
            type="search"
            onChange={(e) => setSearchParam(e.target.value)}
          />
          {loading && <LoaderSpoon className="search-loader" />}
        </FormGroup>
      </Form>
      <div className="table-container">
        {searchResults.length > 0 ? (
          <Table>
            <thead>
              <tr>
                {" "}
                <th>Wallet Address</th>
                <th>Name</th>
                <th>Email</th>
                <th>Mobile</th>
              </tr>
            </thead>
            <tbody>
              {searchResults.map((res, i) => (
                <tr
                  key={i}
                  onClick={() => setSelected(res.customers)}
                  className={
                    selected?._id === res.customers._id ? "selected" : ""
                  }
                >
                  {" "}
                  <td>{res.customers.walletAddress}</td>
                  <td>{res.customers.name}</td>
                  <td>{res.customers.email}</td>
                  <td>{res.customers.mobile}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <>
            {validWalletAddress ? (
              <b className="send-to-address" onClick={handleSubmit}>
                Send to "{searchParam}"
              </b>
            ) : (
              <>
                {searchParam && (
                  <b>No Results Found. Enter the complete wallet address. </b>
                )}
              </>
            )}
          </>
        )}
      </div>

      <hr />

      <Row className="text-center">
        <Button
          className="paidrez-btn col"
          onClick={handleSubmit}
          disabled={!validWalletAddress && !selected}
        >
          Choose
        </Button>
        <Button className="paidrez-btn-secondary col" onClick={closeModal}>
          Cancel
        </Button>
      </Row>
    </div>
  );
};

export default GiftChooseReceiver;
