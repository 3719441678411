import React, { useState } from "react";
import { Button } from "reactstrap";
import whitelistCsvFile from "../../assets/files/whitelist.csv";
import { addToWhitelistCSV } from "services/WhitelistService";

const WhitelistCSV = ({ closeModal, fetchWhitelist, restaurantId }) => {
  const [file, setFile] = useState(null);
  const [error, setError] = useState("");
  const [disableButton, setDisableButton] = useState(true);

  const uploadFile = async () => {
    setDisableButton(true);
    try {
      const res = await addToWhitelistCSV(restaurantId, file);
      console.log(res);
      fetchWhitelist();
      closeModal();
    } catch (e) {
      setDisableButton(false);
      console.log("ERROR::", e);
      setError("Something Went Wrong");
    }
  };

  const handleFileChange = (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    setFile(event.target.files[0]);
    console.log(event.target.files[0]);

    if (event.target?.files[0]?.type !== "text/csv") {
      setDisableButton(true);
      setError("Please select a correct CSV file.");
    } else {
      setError("");
      setDisableButton(false);
    }
  };

  return (
    <div className="whitelist-csv-popup">
      <p className="text-center mb-4">
        <a
          className="template-download-button"
          href={whitelistCsvFile}
          download="whitelist"
        >
          Click Here
        </a>{" "}
        to download the template CSV file.
        <br />
        Fill in the details and upload below.
      </p>

      <input
        type="file"
        name="file"
        accept=".csv"
        onChange={handleFileChange}
        // ref={imageInputRef}
        className="csv-input"
      />

      <Button
        className="d-block mx-auto my-4"
        onClick={() => uploadFile()}
        disabled={disableButton}
      >
        Upload
      </Button>

      {error !== "" && (
        <b className="text-danger">
          <i className="tim-icons icon-alert-circle-exc mr-1 font-weight-bolder" />
          {error.charAt(0).toUpperCase() + error.substring(1)}
        </b>
      )}
    </div>
  );
};

export default WhitelistCSV;
