import axios from "axios";

const RESTAURANTS_BASE_URL =
  process.env.REACT_APP_API_BASE_URL + "/restaurants";
// const RESTAURANTS_BASE_URL = "http://localhost:3001/restaurants";

const BASE_URL_NEW = process.env.REACT_APP_BLOCKCHAIN_BE_SERVER_URL;

class RestaurantService {
  getRestaurantById(id) {
    // return axios.get(RESTAURANTS_BASE_URL + `/restaurant/${id}`);
    return axios.get(BASE_URL_NEW + "/restaurant-by-id/" + id);
  }

  updateRestaurantAbout(restaurantId, restaurantData) {
    // return axios.put(RESTAURANTS_BASE_URL + "/updateRestaurantAbout", {
    //   restaurantId,
    // });
    return axios.post(
      `${BASE_URL_NEW}/update-restaurant/${restaurantId}`,
      restaurantData
    );
  }

  updateRestaurantImage(restaurantId, image) {
    const data = new FormData();
    data.append("restoImage", image);
    return axios.post(
      `${BASE_URL_NEW}/update-restaurant-img/${restaurantId}`,
      data
    );
  }

  getCities() {
    return axios.get(BASE_URL_NEW + "/cities");
  }
}

export default new RestaurantService();
